import Header from "./../Components/partials/header/Header";
import { Outlet } from 'react-router-dom';

export default function AdminLayout() {
    return (
        <div style={{
            height: '100vh',
        }}>
            <Header />
            <div style={{
                marginTop: '64px',
                padding: "30px",
                height: "calc(100% - 64px)",
                overflowY: "auto",
            }}>
                <Outlet />
            </div>
        </div>
    )
}