import React, { useState, useEffect, useContext } from 'react';
import { useCategoria } from '../../../Utils/UCategoria';
import DefaultModal from "../../../Components/ABM/MUIComponents/DefaultModal";
import { Grid, TextField, Typography, Button } from "@mui/material";
import BotonLoading from "../../../Components/ABM/MUIComponents/BotonLoading";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function AMModalCategorias(props) {
    const { isOpen, onClose, categoria, categorias, setCategorias } = props;
    const [nombre, setNombre] = useState('');
    const [loading, setLoading] = useState(false);
    const [tipoProducto, setTipoProducto] = useState(1);

    const { postCategoria, putCategoria } = useCategoria();

    useEffect(() => {
        if (categoria) {
            setNombre(categoria.name);
            setTipoProducto(categoria.tipoProducto);
        }
        else {
            setNombre("")
            setTipoProducto(1);
        }
    }, [categoria]);

    async function handlePostCategoria() {
        setLoading(true);
        try {
            postCategoria(nombre, tipoProducto).then((res) => {
                setCategorias([...categorias, res]);
            });
            handleCloseModal();
        } catch (error) {
            alert(error);
        }
        finally {
            setLoading(false);
        }
    }

    async function handlePutCategoria(id) {
        setLoading(true);
        try {
            putCategoria(id, nombre, tipoProducto).then((res) => {
                setCategorias(categorias.map((categoria) => {
                    if (categoria.id === id) {
                        return { ...res, delete: categoria.delete };
                    }
                    return categoria;
                }));
            });
            handleCloseModal();
        } catch (error) {
            alert(error);
        }
        finally {
            setLoading(false);
        }
    }

    const handleNombreChange = (event) => {
        setNombre(event.target.value);
    };

    const handleCloseModal = () => {
        setNombre("");
        setTipoProducto(1);
        onClose();
    };

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter' && isOpen) {
                categoria ? handlePutCategoria(categoria.id) : handlePostCategoria()
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [categoria, isOpen, handlePutCategoria, handlePostCategoria]);


    return (
        <DefaultModal
            open={isOpen}
            onClose={() => {
                handleCloseModal();
            }}
        >
            <Grid
                container
                item
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                xs={12}
            >
                <Grid
                    container
                    item
                    xs={8}
                    justifyContent="flex-end"
                    style={{ gap: "30px" }}
                >
                    <Typography
                        variant="h4"
                        component="div"
                        style={{ marginBottom: "2vh", width: '100%', textAlign: "center" }}
                    >
                        {categoria ? "Editar Categoria" : "Crear Categoria"}
                    </Typography>
                    <TextField
                        label="Nombre"
                        variant="outlined"
                        value={nombre}
                        fullWidth
                        onChange={handleNombreChange}
                    />
                    <Typography variant="h6" component="div" style={{ marginBottom: "2vh", width: '100%', textAlign: "center" }}>
                        Tipo de animacion al clickear productos
                    </Typography>
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={tipoProducto === 1}
                                    onChange={() => setTipoProducto(1)}
                                />
                            }
                            label={'Girar'}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={tipoProducto === 2}
                                    onChange={() => setTipoProducto(2)}
                                />
                            }
                            label={'Expandir'}
                        />
                    </div>
                    <BotonLoading
                        funcion={categoria ? () => handlePutCategoria(categoria.id) : handlePostCategoria}
                        state={nombre === ""}
                        loading={loading}
                    >
                        {categoria ? "Editar" : "Crear"}
                    </BotonLoading>
                </Grid>
            </Grid>
        </DefaultModal>
    );
}
