import React, { useState, useEffect, useContext } from 'react';
import { Typography, TextField, Grid, useMediaQuery, Autocomplete } from '@mui/material';
import { UserContext } from "../../contexts/UserContext";
import FastfoodIcon from '@mui/icons-material/Fastfood';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import useFetch, { host } from "../../Utils/Fetchs";
import { useSettings } from '../../Utils/settings/Configuraciones';
import CustomizedSnackbars from '../../Components/ABM/MUIComponents/CustomizedSnackbars';
import WebFont from 'webfontloader';
import BotonLoading from '../../Components/ABM/MUIComponents/BotonLoading';

export default function Personalizacion() {
    const [settings, setSettings] = useState([]);
    const [titulo, setTitulo] = useState('');
    const [color1, setColor1] = useState('#000000');
    const [color2, setColor2] = useState('#000000');
    const [color3, setColor3] = useState('#000000');
    const [color1Fuente, setColor1Fuente] = useState('000000');
    const [color2Fuente, setColor2Fuente] = useState('000000');
    const [color3Fuente, setColor3Fuente] = useState('000000');
    const [fontFamily, setFontFamily] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [textoAlert, setTextoAlert] = useState('');
    const isMobile = useMediaQuery('(max-width:600px)');
    const [loading, setLoading] = useState(false);

    const { putFetch, getFetch } = useFetch();
    const { getSettings } = useSettings();
    const { empresaActual } = useContext(UserContext);

    const [fontFamilyOptions, setFontFamilyOptions] = useState([]);

    useEffect(() => {
        if (fontFamily) {
            WebFont.load({
                google: {
                    families: [fontFamily]
                }
            });
        }
    }, [fontFamily]);

    useEffect(() => {
        getFetch(`${host}settings/admin/font-family/`)
            .then(response => {
                setFontFamilyOptions(response);
            })
            .catch(error => console.error('Error al cargar las fuentes:', error));
    }, [])

    useEffect(() => {
        getSettings(empresaActual?.nombre)
            .then(response => {
                const newSettings = response[0];
                setSettings(newSettings);
                setTitulo(newSettings.nombre);
                setColor1(newSettings.background_color1);
                setColor2(newSettings.background_color2);
                setColor3(newSettings.background_color3);
                setColor1Fuente(newSettings.color1);
                setColor2Fuente(newSettings.color2);
                setColor3Fuente(newSettings.color3);
                setFontFamily(newSettings.font_family);
            })
            .catch(error => console.error('Error al cargar las configuraciones:', error));
    }, []);

    async function handlePutSettings() {
        try {
            const data = { id: settings.id, nombre: titulo };
            if (color1 !== settings.background_color1) data.background_color1 = color1;
            if (color2 !== settings.background_color2) data.background_color2 = color2;
            if (color3 !== settings.background_color3) data.background_color3 = color3;
            if (color1Fuente !== settings.color1) data.color1 = color1Fuente;
            if (color2Fuente !== settings.color2) data.color2 = color2Fuente;
            if (color3Fuente !== settings.color3) data.color3 = color3Fuente;
            if (fontFamily) data.font_family = fontFamily;

            putFetch(`${host}settings/admin/temas/${empresaActual?.nombre}/`,
                data
            );
            setTextoAlert('Página actualizada correctamente');
            setSnackbarOpen(true);
        } catch (error) {
            setTextoAlert('Error al actualizar la página');
            setSnackbarOpen(true);;
        }
    }

    const handleColor1Change = (event) => {
        setColor1(event.target.value);
    };

    const handleColor2Change = (event) => {
        setColor2(event.target.value);
    };

    const handleColor3Change = (event) => {
        setColor3(event.target.value);
    };

    const handleColor1FuenteChange = (event) => {
        setColor1Fuente(event.target.value);
    };

    const handleColor2FuenteChange = (event) => {
        setColor2Fuente(event.target.value);
    };

    const handleColor3FuenteChange = (event) => {
        setColor3Fuente(event.target.value);
    };

    return (
        <div style={{
            height: '100%',
            display: 'flex', flexDirection: 'column', alignItems: 'center'
        }}>
            <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: '75px' }}>
                <div>
                    <Grid
                        container
                    >
                        <Grid
                            item
                            container
                            justifyContent={"space-between"}
                            sx={{
                                border: '1px solid #c1c1c1', borderRadius: '10px', paddingX: '10px',
                                width: '320px', marginTop: '30px', marginLeft: isMobile ? '15px' : '30px'
                            }}>
                            <Typography style={{ fontSize: '200%', marginTop: '20px' }}>Colores de fondo</Typography>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '30px' }}>
                                <div style={{
                                    marginBottom: '30px', display: 'flex', flexDirection: 'column',
                                    marginTop: '30px', gap: '20px',
                                }}>

                                    <Typography variant="h6">Color para el fondo principal:</Typography>
                                    <input type="color" value={color1} onChange={handleColor1Change}
                                        style={{ width: '100px', height: '30px', marginBottom: '10px' }} />

                                    <Typography variant="h6" style={{ marginRight: '10px' }}>Color de los productos y el fondo del título:</Typography>
                                    <input type="color" value={color2} onChange={handleColor2Change}
                                        style={{ width: '100px', height: '30px', marginBottom: '10px' }} />

                                    <Typography variant="h6" style={{ marginRight: '10px' }}>Color para los botones:</Typography>
                                    <input type="color" value={color3} onChange={handleColor3Change}
                                        style={{ width: '100px', height: '30px' }} />
                                </div>
                            </div>
                        </Grid>
                    </Grid >
                    <Grid
                        container
                    >
                        <Grid
                            item
                            container
                            justifyContent={"space-between"}
                            sx={{
                                border: '1px solid #c1c1c1', borderRadius: '10px', paddingX: '10px',
                                width: '320px', marginTop: '30px', marginLeft: isMobile ? '15px' : '30px',
                                height: '175px'
                            }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ marginBottom: isMobile ? '70px' : '0px' }}>
                                    <Typography style={{ fontSize: '200%', marginTop: '20px' }}>Título</Typography>
                                    <TextField value={titulo} onChange={(e) => setTitulo(e.target.value)}
                                        style={{ width: '200px', marginTop: '20px' }} />
                                </div>
                            </div>
                        </Grid>
                    </Grid >
                </div>
                {!isMobile &&
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: '30px',
                    }}>
                        <div style={{
                            width: '400px',
                            height: '5%',
                            backgroundColor: color2,
                        }}>
                            <Typography style={{ color: color1Fuente, textAlign: 'center', fontSize: '1.25em', marginTop: '3px', fontFamily: fontFamily }}>{titulo ? titulo : "Título"}</Typography>
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '400px',
                            height: '500px',
                            backgroundColor: color1,
                        }}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {[...Array(3)].map((_, index) => (
                                    <button
                                        key={index}
                                        className={`padding-2 bn3`}
                                        style={{
                                            backgroundColor: color3,
                                            height: '20px',
                                            fontSize: '0.5em',
                                            paddingInline: '20px',
                                            cursor: 'pointer',
                                            border: 'none',
                                            marginBottom: '10px',
                                            marginTop: '10px',
                                            color: color2Fuente,
                                            marginRight: index !== 2 ? '5px' : '0',
                                            fontFamily: fontFamily
                                        }}
                                    >
                                        Categoria
                                    </button>
                                ))}
                            </div>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexWrap: 'wrap',
                                width: '100%',
                            }}>
                                {[...Array(6)].map((_, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            width: '180px',
                                            height: '120px',
                                            backgroundColor: color2,
                                            margin: '5px',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            borderRadius: '10px',
                                            color: 'white',
                                        }}
                                    >
                                        {settings?.tipo !== "Carta" ?
                                            <ShoppingBagIcon style={{ fontSize: '300%', margin: '5px', color: color3Fuente }} /> :
                                            <FastfoodIcon style={{ fontSize: '300%', margin: '5px', color: color3Fuente }} />}
                                        <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'row', gap: '80px' }}>
                                            <Typography style={{ fontSize: '0.5em', color: color3Fuente, fontFamily: fontFamily }}>Nombre</Typography>
                                            <Typography style={{ fontSize: '0.5em', color: color3Fuente }}>$999</Typography>
                                        </div>
                                        <button
                                            key={index}
                                            className={`padding-2 bn3`}
                                            style={{
                                                backgroundColor: color3,
                                                height: '15px',
                                                fontSize: '0.5em',
                                                width: '90%',
                                                paddingInline: '20px',
                                                cursor: 'pointer',
                                                border: 'none',
                                                marginTop: '10px',
                                                marginRight: index !== 2 ? '5px' : '0',
                                                color: color2Fuente,
                                                fontFamily: fontFamily
                                            }}
                                        >
                                            Acerca de
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                }
                <Grid
                    container
                >
                    <Grid
                        item
                        container
                        justifyContent={"space-between"}
                        sx={{
                            border: '1px solid #c1c1c1', borderRadius: '10px', padding: '10px',
                            paddingY: '30px', width: '320px', marginTop: isMobile ? '-20px' : '30px', marginLeft: isMobile ? '15px' : '0px'
                        }}>
                        <div style={{ marginBottom: isMobile ? '40px' : '0px' }}>
                            <Typography style={{ fontSize: '200%' }}>Personalizacion de fuente</Typography>
                            <div style={{
                                display: 'flex', flexDirection: 'column', marginTop: '30px', gap: '10px',
                                marginLeft: '30px'
                            }}>
                                <Typography variant="h6" style={{ marginRight: '10px' }}>Color para la fuente del título:</Typography>
                                <input type="color" value={color1Fuente} onChange={handleColor1FuenteChange}
                                    style={{ width: '100px', height: '30px' }} />

                                <Typography variant="h6" style={{ marginRight: '10px' }}>Color para la fuente de los botones:</Typography>
                                <input type="color" value={color2Fuente} onChange={handleColor2FuenteChange}
                                    style={{ width: '100px', height: '30px' }} />

                                <Typography variant="h6" style={{ marginRight: '10px' }}>Color para la fuente de los productos:</Typography>
                                <input type="color" value={color3Fuente} onChange={handleColor3FuenteChange}
                                    style={{ width: '100px', height: '30px' }} />
                                <Autocomplete
                                    freeSolo
                                    value={fontFamily}
                                    style={{ marginTop: '40px', width: '90%' }}
                                    options={fontFamilyOptions}
                                    filterOptions={(options, { inputValue }) =>
                                        inputValue
                                            ? options.filter((option) => option.toLowerCase().startsWith(inputValue.toLowerCase()))
                                            : options
                                    }
                                    renderInput={(params) =>
                                        <TextField {...params}
                                            label="Tipo de Fuente"
                                        />}
                                    renderOption={(props, option) => (
                                        <div {...props} style={{ fontFamily: option }}>
                                            {option}
                                        </div>
                                    )}
                                    onChange={(event, newValue) => {
                                        setFontFamily(newValue);
                                    }}
                                />
                            </div>
                        </div>
                    </Grid>
                </Grid >
            </div>
            <div style={{ width: '200px' }}>
                <BotonLoading
                    funcion={handlePutSettings}
                    loading={loading}
                >
                    Guardar
                </BotonLoading>
            </div>
            <div style={{ color: 'white' }}>.</div>

            <CustomizedSnackbars
                texto={textoAlert}
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
            />
        </div >
    );
};
