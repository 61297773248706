import { SettingsProvider } from './contexts/SettingsContext';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { UserProvider } from "./contexts/UserContext";
import AdminDashboard from "./dashboards/AdminDashboard";
import PublicDashboard from "./dashboards/PublicDashboard";
import Home from "./pages/karta/home/Home";

export default function App() {
    return (
        <Router>
            <Routes>
                <Route path={"/:variable//*"} element={
                    <SettingsProvider>
                        <PublicDashboard />
                    </SettingsProvider>
                } />

                <Route path={'/'} element={<Home />} />

                <Route path={'/admin/*'} element={
                    <UserProvider>
                        <AdminDashboard />
                    </UserProvider>
                } />

            </Routes>
        </Router>
    )
}