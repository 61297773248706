import { useContext } from 'react';
import { SettingsContext } from '../../../../../contexts/SettingsContext';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useMediaQuery } from '@mui/material';

export default function Tipo1({ producto, index, click, setClick }) {
    const { settings } = useContext(SettingsContext);
    const isMobile = useMediaQuery('(max-width: 450px)');

    function handleGirar() {
        let e = document.getElementById('card' + producto.id);
        e.classList.add('flip-vertical-right');
        setTimeout(() => {
            setClick(false);
            e.classList.remove('flip-vertical-right');
        }, 200);
    }

    function BackCard() {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    backgroundColor: settings?.background_color2,
                    padding: '15px',
                    flexGrow: 1,
                    height: '100%',
                    cursor: 'pointer',
                }}
                onClick={() => {
                    handleGirar();
                }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: 'calc(100% - 45px)',
                    overflowY: 'auto',
                }}>
                    {producto.Descripcion ?
                        !producto.TipoDescripcion ?
                            <p style={{
                                textAlign: 'center',
                                color: settings?.color2,
                                margin: 'auto',
                                fontFamily: settings?.font_family,
                                fontSize: isMobile ? '0.8em' : '1em',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '11',
                                WebkitBoxOrient: 'vertical',
                            }}>
                                {producto.Descripcion}
                            </p>
                            :
                            <ul style={{
                                color: settings?.color2,
                                fontFamily: settings?.font_family,
                                fontSize: isMobile ? '1em' : '1.2em',
                                textOverflow: 'ellipsis',
                            }}>
                                {producto.Descripcion.split('-').map((item, index) => {
                                    return <li key={index} style={{ padding: '10px' }}> • {item}</li>
                                })}
                            </ul>

                        :
                        <p style={{ textAlign: 'center', color: settings?.color2, fontFamily: settings?.font_family }}>Sin descripción</p>
                    }
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', }}>
                    <button
                        style={{
                            backgroundColor: settings?.background_color3,
                            borderRadius: '25px',
                            color: settings?.color3,
                            border: 'none',
                            fontSize: '1em',
                            cursor: 'pointer',
                            width: '100%',
                            height: '35px',
                            alignContent: 'center',
                            fontFamily: settings?.font_family
                        }}
                        onClick={() => {
                            handleGirar();
                        }}
                    >
                        Volver
                    </button>
                    {settings?.telefono &&
                        <a
                            href={`https://wa.me/${settings.telefono}?text=Hola,%20me%20gustaría%20consultar%20por%20${encodeURIComponent(producto.Nombre)}`}
                            target='_blank'
                            rel='noreferrer'
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textDecoration: 'none',
                                marginLeft: '15px',
                            }}
                        >
                            <WhatsAppIcon
                                style={{
                                    fontSize: '1.5em',
                                    cursor: 'pointer',
                                    color: settings?.color3
                                }}
                            />
                        </a>
                    }
                </div>
            </div>
        )
    }



    return (
        <BackCard />
    );
}
