import { Grid } from "@mui/material";
import Producto from "./producto/Producto";

export default function Productos(props) {
    const { categoria } = props;

    return (
        <Grid container spacing={2} sx={{
            paddingX: 2,
            height: "100%",
            paddingTop: 2,
            paddingBottom: "200px",
            overflowY: "auto",
            overflowX: "hidden",
            '&::-webkit-scrollbar': {
                display: 'none'
            },
            scrollbarWidth: 'none',
        }}
        >
            {categoria.products.map((producto, index) => (
                <Grid item key={producto.id} xs={6} sm={4} md={3} lg={3} xl={2}
                    className={index % 2 === 0 ? 'slide-left' : 'slide-right'}
                    sx={{
                        height: 300,
                    }}
                >
                    <Producto producto={producto} index={index} tipo={categoria.tipoProducto} />
                </Grid>
            ))}
        </Grid>
    )
}