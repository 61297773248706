
import FrontCard from "./FrontCard";
import Tipo1 from "./tiposProducto/Tipo1";
import { useState } from "react";
import { SettingsContext } from "../../../../contexts/SettingsContext";
import { useContext } from "react";
import Tipo2 from "./tiposProducto/Tipo2";
import useFetch, { host } from "../../../../Utils/Fetchs";

export default function Producto(props) {
    const { producto, index, tipo } = props;

    const { postFetch } = useFetch();

    const [click, setClick] = useState(false);
    const { settings } = useContext(SettingsContext);

    function funcionTipo1() {
        let e = document.getElementById('card' + producto.id);
        e.classList.add('flip-vertical-right');
        setTimeout(() => {
            setClick(true)
            e.classList.remove('flip-vertical-right');
        }, 200);

    }

    const tiposProductos = [
        { id: 1, componente: Tipo1, funcion: funcionTipo1 },
        { id: 2, componente: Tipo2, funcion: () => { setClick(true) } },
    ]

    let tipoProducto = tiposProductos.find(t => t.id === tipo);


    if (producto.detalles && producto.detalles.length > 0) {
        tipoProducto = tiposProductos[1]
    }

    const ComponenteProducto = tipoProducto.componente;

    function funcionTipo() {
        tipoProducto.funcion();
        postFetch(host + 'estadisticas/interacciones/', {
            id: producto.id,
        })
    }

    return (
        <div style={{
            borderRadius: '25px',
            overflow: 'hidden',
            //boxShadow: `0px 4px 10px 0px ${settings?.background_color2}`,
            width: '100%',
            height: '100%',
        }}

            id={'card' + producto.id}
        >
            {!click ?
                <FrontCard producto={producto} settings={settings} funcion={funcionTipo} />
                :
                <ComponenteProducto producto={producto} index={index} click={click} setClick={setClick} />
            }
        </div >
    );
}