import React, { useState, useEffect, useContext } from 'react';
import { useProducto } from '../../../Utils/UProducto';
import useFetch, { host } from '../../../Utils/Fetchs';
import { Grid, Tooltip, Button, Typography, Dialog, Select, MenuItem, useMediaQuery, Autocomplete, TextField, IconButton } from "@mui/material";
import { GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { esES } from "@mui/x-data-grid";
import CustomDataGrid from "../../../Components/ABM/MUIComponents/CustomDataGrid";
import AMModalProduct from "./AMModalProduct";
import SwitchIOS from "../../../Components/ABM/MUIComponents/SwitchIOS";
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import BotonLoading from '../../../Components/ABM/MUIComponents/BotonLoading';

export default function ABMProductos() {
    const [productos, setProductos] = useState([]);
    const [productoSeleccionado, setProductoSeleccionado] = useState('');
    const [openDialog, setOpenDialog] = useState({ open: false });
    const [openModal, setOpenModal] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [columnaFiltrar, setColumnaFiltrar] = useState("name");
    const [valorFiltrar, setValorFiltrar] = useState("");
    const [loading, setLoading] = useState(false);
    const [detalles, setDetalles] = useState([]);

    const { getProductos, deleteProducto } = useProducto();
    const { postFetch, getFetch } = useFetch();

    useEffect(() => {
        getProductos()
            .then(response => {
                setProductos(response);
            })
            .catch(error => console.error('Error al cargar productos:', error));

        getFetch(`${host}detalles-producto/`, true)
            .then(response => {
                console.log(response);
                setDetalles(response);
            })
            .catch(error => console.error('Error al cargar detalles:', error));
    }, []);

    function handleOpenModal(producto) {
        if (openModal) {
            setProductoSeleccionado(null);
            setOpenModal(false);
        } else {
            if (producto) setProductoSeleccionado(producto);
            setOpenModal(true);
        }
    }

    function handleDeleteProducto(id) {
        setLoading(true);
        deleteProducto(id).finally(() => setLoading(false))
    }

    useEffect(() => {
        setValorFiltrar("");
    }, [productos]);

    function handleOpenDialog(row) {
        if (row) {
            setProductoSeleccionado(row);
            setOpenDialog({
                open: true,
                action: row.delete ? 'activar' : 'desactivar',
                handleAction: () => {
                    handleDeleteProducto(row.id);
                    setProductos(productos.map(producto => {
                        if (producto.id === row.id) {
                            return { ...producto, delete: !producto.delete };
                        }
                        return producto;
                    }));
                    setProductoSeleccionado(null);
                    setOpenDialog({ open: false });
                }
            });
        }
    }

    function handleOpenDialogEliminar(row) {
        if (row) {
            setProductoSeleccionado(row);
            setOpenDialog({
                open: true,
                action: 'eliminar',
                handleAction: () => {
                    postFetch(`${host}productos/borrar/`, { id: row.id }, true)
                        .then(response => {
                            setProductos(productos.filter(producto => producto.id !== row.id));
                        })
                        .finally(() => {
                            setProductoSeleccionado(null);
                            setOpenDialog({ open: false, action: '', handleAction: null });
                        });
                }
            });
        }
    }

    function handleCloseDialog(shouldExecuteAction) {
        if (shouldExecuteAction && openDialog.handleAction) {
            openDialog.handleAction();
        } else {
            setProductoSeleccionado(null);
            setOpenDialog({ open: false });
        }
    }

    function CustomGridToolBar() {
        return (
            <GridToolbarContainer style={{
                display: 'flex', alignItems: 'center',
                justifyContent: isMobile ? 'center' : 'space-between'
            }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenModal()}
                >
                    Crear Producto
                </Button>
                {!isMobile && (
                    <>
                        <GridToolbarExport />
                    </>
                )}
            </GridToolbarContainer>
        )
    }

    let columns = [
        { field: "name", headerName: "Nombre", flex: 1, maxWidth: 150, filterable: false, },
        {
            field: "precio", headerName: "Precio", flex: 1, maxWidth: 100, filterable: false,
            renderCell: (params) => {
                return (
                    <Typography style={{ fontSize: '1em' }}>
                        ${params.row.precio}
                    </Typography>
                );
            }
        },
        { field: "description", headerName: "Descripcion", flex: 1, filterable: false, },
        {
            field: "categorias", headerName: "Categorias", flex: 1, filterable: false,
            sortable: false, maxWidth: 165,
            renderCell: (params) => {
                return (
                    <Select
                        style={{ height: '30px', width: '150px' }}
                        displayEmpty
                    >
                        {params.row.categorias.map((categoria) => (
                            <MenuItem disabled style={{ opacity: 1 }}>{categoria.name}</MenuItem>
                        ))}
                    </Select>
                );
            }
        },
        {
            field: "image", headerName: "Imagen", align: "center", filterable: false, flex: 1, maxWidth: 100,
            renderCell: (params) =>
                <Tooltip title="">
                    {params.row.image ?
                        <DoneIcon
                            style={{ color: 'green' }}
                        />
                        :
                        <CloseIcon
                            style={{ color: 'red' }}
                        />}
                </Tooltip>
        },
        {
            field: "delete", headerName: "Activa", flex: 1, maxWidth: 100, filterable: false,
            renderCell: (params) =>
                <Tooltip title="Estado" >
                    <SwitchIOS
                        onChange={() => handleOpenDialog(params.row)}
                        checked={!params.row.delete}
                    />
                </Tooltip>
        },
        {
            field: "Acciones",
            headerName: "Acciones",
            filterable: false,
            disableExport: true,
            sortable: false,
            flex: 1,
            maxWidth: 150,
            renderCell: (params) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => handleOpenModal(params.row)}
                        >
                            Editar
                        </Button>
                        <IconButton
                            onClick={() => handleOpenDialogEliminar(params.row)}
                            sx={{ marginLeft: '10px' }}
                        >
                            <DeleteIcon sx={{ color: 'red' }} />
                        </IconButton>
                    </div>
                );
            }
        }
    ];

    function productosFiltrados() {
        if (columnaFiltrar && valorFiltrar) {
            if (columnaFiltrar === "categorias") {
                return (productos.filter(c => c[columnaFiltrar]?.map(c => c.name).join(" ").toLowerCase().includes(valorFiltrar.toLowerCase())));
            } else
                return (productos.filter(c => c[columnaFiltrar]?.toString().toLowerCase().includes(valorFiltrar.toLowerCase())));
        }

        if (!valorFiltrar) {
            return (productos);
        }
    }

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            style={{
                height: "100%",

            }}
        >
            <Grid item xs={12} style={{ width: "100%", height: "100%" }} >
                <Grid container gap={2} marginBottom={2}>
                    <Autocomplete
                        options={columns.filter(c => c.headerName !== "Acciones" && c.headerName !== "Activa" && c.headerName !== "Imagen" && c.headerName !== "Editar").map(c => c.headerName)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Columna"
                                style={{
                                    width: 200,
                                    color: productosFiltrados().length === 0 && valorFiltrar ? "red" : "inherit",
                                }}
                                InputLabelProps={{
                                    style: { color: productosFiltrados().length === 0 && valorFiltrar ? "red" : "inherit" },
                                }}
                            />
                        )}
                        value={columnaFiltrar ? columns.find(c => c.field === columnaFiltrar)?.headerName : ""}
                        onChange={(e, value) => {
                            setColumnaFiltrar(columns.find(c => c.headerName === value)?.field);
                            setValorFiltrar("");
                        }}
                        style={{ width: 200 }}
                    />
                    <TextField
                        disabled={!columnaFiltrar}
                        placeholder='Buscar...'
                        value={valorFiltrar}
                        onChange={(e) => setValorFiltrar(e.target.value)}
                        style={{
                            width: 200,
                            color: productosFiltrados().length === 0 && valorFiltrar ? "red" : "inherit",
                            border: productosFiltrados().length === 0 && valorFiltrar ? "1px red" : "",
                        }}
                        InputLabelProps={{
                            style: { color: productosFiltrados().length === 0 && valorFiltrar ? "red" : "inherit" },
                        }}
                    />
                    {valorFiltrar && columnaFiltrar && (
                        <Typography variant="h6" style={{ marginTop: '10px', color: productosFiltrados().length === 0 && valorFiltrar ? "red" : "inherit" }}>
                            {productosFiltrados().length} productos encontrados
                        </Typography>
                    )}
                </Grid>
                <CustomDataGrid
                    rows={productosFiltrados()}
                    columns={columns}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'codigo', sort: 'asc' }]
                        },
                        columns: isMobile && {
                            columnVisibilityModel: {
                                image: false,
                                categorias: false,
                                description: false,
                                precio: false,
                            }
                        }
                    }}
                    pageSize={5}
                    disableRowSelectionOnClick
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    slots={{ toolbar: CustomGridToolBar }}
                    style={{ flexGrow: 1 }}
                    sx={{ height: "90%" }}
                />
            </Grid>
            <Dialog
                open={openDialog.open}
                onClose={() => setOpenDialog({ open: false })}
            >
                <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    style={{ gap: "20px", textAlign: "center", padding: "50px", }}
                >
                    <Typography
                        variant="h6"
                        component="div"
                        style={{
                            textAlign: "center"
                        }}
                    >
                        ¿Estás seguro que deseas {openDialog.action} el producto?
                    </Typography>
                    <Grid
                        item
                        container
                        xs={12}
                        justifyContent="center"
                        style={{ gap: "30px" }}
                    >
                        <Button variant="contained" color={"primary"}
                            onClick={() => handleCloseDialog(false)}
                        >
                            Cancelar
                        </Button>
                        <BotonLoading
                            loading={loading}
                            funcion={() => handleCloseDialog(true)}
                            color={openDialog.action === 'activar' ? "green" : "red"}
                            width="100px"
                        >
                            {openDialog.action === 'eliminar' ? "Eliminar" : (openDialog.action === 'activar' ? "Activar" : "Desactivar")}
                        </BotonLoading>
                    </Grid>
                </Grid>
            </Dialog>
            <AMModalProduct
                isOpen={openModal}
                onClose={handleOpenModal}
                producto={productoSeleccionado}
                productos={productos}
                setProductos={setProductos}
                detalles={detalles}
                setDetalles={setDetalles}
                resetFiltros={() => {
                    setColumnaFiltrar("name");
                    setValorFiltrar("");
                    setProductoSeleccionado(null);
                }
                }
            />
        </Grid>
    );
}