import useFetch, { host } from "../Fetchs";

export function useWifi() {
    const { getFetch, putFetch } = useFetch();

    async function getWifi() {
        return getFetch(`${host}settings/admin/wifi/`, true);
    }

    async function putWifi(data) {
        return putFetch(`${host}settings/admin/wifi/`, data, true);
    }

    return { getWifi, putWifi };
}
