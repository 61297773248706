import { Box, IconButton, Link, Modal } from '@mui/material';
import { host } from '../../../../../Utils/Fetchs';
import CloseIcon from '@mui/icons-material/Close';

export default function VideoRender(props) {
    const { empresa, onClose } = props;
    return (
        <Modal
            open={empresa !== null}
            onClose={onClose}
            sx={{
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    width: 450,
                    height: 800,
                    maxWidth: '100%',
                    maxHeight: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                    objectFit: 'cover',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'black',
                    borderRadius: '10px',
                }}
            >
                <Box sx={{

                    width: "100%",
                    height: "100%",
                    zIndex: 1000,
                }}>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            background: "rgba(0,0,0,0.6)",
                            border: "1px solid white",
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            color: 'white',
                            margin: "10px",
                            "&:hover": {
                                background: "rgba(0,0,0,.8)",
                            }
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    height: "20%",
                    background: 'linear-gradient(0deg, rgba(0,0,0,.7) 0%, rgba(0,0,0,0) 100%)',
                    zIndex: 1000,
                    paddingLeft: "20px",
                    display: "flex",
                    alignItems: "center"

                }}>
                    <Link sx={{
                        textDecoration: 'none',
                        borderRadius: "40px",
                        backgroundColor: "red",
                        width: "35%",
                        height: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "rgba(0,0,0,0.5)",
                        border: "1px solid white",
                        color: "white",
                        cursor: "pointer",
                        fontWeight: "bold",
                        "&:hover": {
                            background: "rgba(0,0,0,.7)",
                        }
                    }}
                        href={`/${empresa?.nombre}`}
                    >
                        Ver Catalogo
                    </Link>
                </Box>
                <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: "auto",
                        objectFit: 'cover',
                    }}
                >
                    <source src={`${host}${empresa?.video_intro}`} type="video/mp4" />
                </video>
            </Box>
        </Modal>
    )
}