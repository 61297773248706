import { AppBar, Toolbar, IconButton, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useState } from "react";
import SideBar from "./SideBar";

export default function Header(props) {
  const [sideBarOpen, setSideBarOpen] = useState(false);

  const { rubros, setRubroSeleccionado, rubroSeleccionado } = props;

  const toggleSideBar = () => {
    setSideBarOpen(!sideBarOpen);
  };


  return (
    <>
      <AppBar position="fixed">
        <Toolbar style={{ justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              onClick={toggleSideBar}
              edge="start"
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {rubroSeleccionado ? rubroSeleccionado.nombre : "Marketplace"}
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
      <SideBar open={sideBarOpen} onClose={toggleSideBar} rubros={rubros} rubroSeleccionado={rubroSeleccionado} setRubroSeleccionado={setRubroSeleccionado} />
    </>
  );
};