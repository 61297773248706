import React, { useState, useEffect } from 'react';
import { Box, Grid, TextField, InputAdornment, Button } from '@mui/material';
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import { useWifi } from '../../Utils/settings/UWifi';
import CustomizedSnackbars from '../../Components/ABM/MUIComponents/CustomizedSnackbars';
import BotonLoading from '../../Components/ABM/MUIComponents/BotonLoading';

export default function Wifi() {
    const [wifiNombre, setWifiNombre] = useState('');
    const [wifiClave, setWifiClave] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const { getWifi, putWifi } = useWifi();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [textoAlert, setTextoAlert] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getWifi()
            .then(response => {
                setWifiNombre(response.red);
                setWifiClave(response.clave);
            })
            .catch(error => console.error('Error al cargar el wifi:', error));
    }, []);

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    async function handlePutWifi() {
        setLoading(true);
        const data = { red: wifiNombre, clave: wifiClave };
        try {
            await putWifi(data);
            setTextoAlert('Wifi actualizado correctamente');
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error al guardar la configuración del wifi:', error);
            setTextoAlert('Error al actualizar el wifi');
            setSnackbarOpen(true);
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ height: '90vh', }}
        >
            <Grid
                item
                container
                direction={"column"}
                justifyContent={"space-between"}
                sx={{
                    border: '1px solid #c1c1c1',
                    borderRadius: '10px',
                    padding: '30px',
                    paddingY: '50px',
                    width: '320px',
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '10px', gap: '40px', alignItems: 'center' }}>
                        <TextField value={wifiNombre} label="Nombre de la red" variant="outlined" style={{ width: '100%' }}
                            onChange={(event) => setWifiNombre(event.target.value)} />
                        <TextField
                            value={wifiClave}
                            label="Contraseña de la red"
                            variant="outlined"
                            style={{ width: '100%' }}
                            onChange={(event) => setWifiClave(event.target.value)}
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {showPassword ? (
                                            <VisibilityOffIcon onClick={handleTogglePasswordVisibility} style={{ cursor: 'pointer' }} />
                                        ) : (
                                            <VisibilityIcon onClick={handleTogglePasswordVisibility} style={{ cursor: 'pointer' }} />
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <BotonLoading
                            funcion={handlePutWifi}
                            loading={loading}
                        >
                            Guardar
                        </BotonLoading>

                    </Box>
                </div>
            </Grid>
            <CustomizedSnackbars
                texto={textoAlert}
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
            />
        </Grid >
    );
}