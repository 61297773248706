import React, { useContext, useState } from 'react';
import { SettingsContext } from "../../../contexts/SettingsContext";
import useFetch, { host } from "../../../Utils/Fetchs";
import { Box, Button, Typography, TextField, CircularProgress, Grid, useMediaQuery } from '@mui/material';
import { useNavigate } from "react-router-dom";

export default function FinalizarCompra() {
    const { settings, carrito, setCarrito, getEmpresa, planes, setCarritoDrawer } = useContext(SettingsContext);
    const [nombre, setNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [email, setEmail] = useState('');
    const [telefono, setTelefono] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [loading, setLoading] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');

    const { postFetch } = useFetch();
    const navigate = useNavigate();

    async function postOrder() {
        let postData = {
            empresa: getEmpresa(),
            items: carrito.map(item => ({
                producto: item.id,
                cantidad: item.cantidad,
            })),
            nombre,
            apellido,
            email,
            telefono,
            descripcion,
        };

        try {
            setLoading(true);
            await postFetch(host + 'pedidos/', postData);
        } catch (e) {
            alert(e);
        } finally {
            setLoading(false);
        }
    }

    async function makeOrder() {
        if (planes.includes('2')) await postOrder();

        let wppMessage = `Hola, soy *${nombre} ${apellido}*. Quiero pedir lo siguiente:\n\n`;

        carrito.forEach((item, index) => {
            wppMessage += `${index + 1}. *${item.Nombre}* x *${item.cantidad}* = $${item.Precio * item.cantidad}\n`;
        });

        wppMessage += `Email: ${email}\n`;
        wppMessage += `Teléfono: ${telefono}\n\n`;
        wppMessage += `\nEspecificaciones: ${descripcion}\n\n`;
        wppMessage += `Total: $${carrito.reduce((acc, item) => acc + item.Precio * item.cantidad, 0)}`;

        window.open(`https://wa.me/${settings.telefono}?text=${encodeURIComponent(wppMessage)}`, '_blank');
        setCarritoDrawer(false);
        setCarrito([]);
        setNombre('');
        setApellido('');
        setEmail('');
        setTelefono('');
        setDescripcion('');
    }

    return (
        <Grid container display={'flex'} >
            <Box sx={{
                p: 4,
                gap: 3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                maxHeight: '100vh',
                overflowY: 'auto',
                width: '100%'
            }}>
                <Typography variant="h5">Finalizar Compra</Typography>
                <TextField
                    label="Nombre"
                    variant="outlined"
                    sx={{ width: isMobile ? '100%' : '60%' }}
                    value={nombre}
                    onChange={(e) => setNombre(e.target.value)}
                />
                <TextField
                    label="Apellido"
                    variant="outlined"
                    sx={{ width: isMobile ? '100%' : '60%' }}
                    value={apellido}
                    onChange={(e) => setApellido(e.target.value)}
                />
                <TextField
                    label="Email"
                    variant="outlined"
                    sx={{ width: isMobile ? '100%' : '60%' }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    label="Teléfono"
                    variant="outlined"
                    sx={{ width: isMobile ? '100%' : '60%' }}
                    value={telefono}
                    onChange={(e) => setTelefono(e.target.value)}
                    inputProps={{ maxLength: 12 }}
                />
                <TextField
                    variant="outlined"
                    sx={{ width: isMobile ? '100%' : '60%' }}
                    value={descripcion}
                    onChange={(e) => setDescripcion(e.target.value)}
                    rows={2}
                    multiline
                    placeholder="Casa sobre la esquina"
                    label="Especificaciones"
                />
                <Typography variant="h6" gutterBottom>Total: ${carrito.reduce((acc, item) => acc + item.Precio * item.cantidad, 0)}</Typography>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '10px',
                    cursor: 'pointer',
                    borderRadius: '5px',
                    backgroundColor: 'green',
                    width: '70%',
                }}
                    disabled={nombre === '' || apellido === '' || email === '' || telefono === '' || loading}
                    onClick={makeOrder}
                >
                    <Typography variant="h6" sx={{ color: 'white' }}>Confirmar Pedido</Typography>
                </Box>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => { navigate(-1) }}
                    sx={{ width: '50%' }}
                >
                    Volver
                </Button>
            </Box>
        </Grid>
    )
}
