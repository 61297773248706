import useFetch, { host } from '../../../../Utils/Fetchs';
import { useContext, useEffect, useState } from 'react';
import { SettingsContext } from '../../../../contexts/SettingsContext';
import Header from './components/Header';
import { Box, Grid, IconButton, Link, Modal } from '@mui/material';
import VideoRender from './components/VideoRender';
import CloseIcon from '@mui/icons-material/Close';


export default function Marketplace() {
    const [rubros, setRubros] = useState([]);
    const [rubroSeleccionado, setRubroSeleccionado] = useState(null);
    const [showVideo, setShowVideo] = useState(null);
    const [anuncio, setAnuncio] = useState(null);
    const [showAnuncio, setShowAnuncio] = useState(true);

    const { getFetch } = useFetch();

    const { settings, wifi, getSettings, planes, getCantidadCarrito, getEmpresa, carritoDrawer, setCarritoDrawer } = useContext(SettingsContext)

    useEffect(() => {
        getFetch(`${host}rubros/${getEmpresa()}/`)
            .then(data => {
                setAnuncio(data.anuncio)
                if (!data.anuncio) setShowAnuncio(false)
                setRubros(data.rubros)
            }
            )
    }, [])

    if (showAnuncio && anuncio) {
        return (
            <Modal
                open={showAnuncio}
                onClose={() => setShowAnuncio(false)}
                disableAutoFocus
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box sx={{
                    position: 'relative',
                    width: "90"
                }}>
                    <img src={host + anuncio} style={{ width: '100%', cursor: 'pointer' }} onClick={() => setShowAnuncio(false)} />

                    <IconButton
                        sx={{
                            position: 'fixed',
                            top: '10px',
                            right: '10px',
                            color: 'white',
                        }}
                        onClick={() => setShowAnuncio(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Modal>
        )
    }

    if (!showAnuncio) {
        return (
            <div>
                <Header rubros={rubros} setRubroSeleccionado={setRubroSeleccionado} rubroSeleccionado={rubroSeleccionado} />
                <Grid container marginTop={7} sx={{
                    padding: '20px',
                }}>
                    <h1>Marketplace</h1>
                    <Grid container sx={{
                        paddingTop: '20px',
                    }}>
                        {rubroSeleccionado && rubroSeleccionado.empresas.map(empresa => (
                            <Box sx={{
                                color: 'black',
                                border: '1px solid black',
                                padding: '30px',
                                borderRadius: '10px',
                                cursor: "pointer",
                                "&:hover": {
                                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                                },
                                fontWeight: 'bold',
                            }}
                                onClick={() => {
                                    setShowVideo(empresa);
                                }}
                            >
                                {empresa.nombre}
                            </Box>
                        ))}

                    </Grid>
                </Grid>
                <VideoRender empresa={showVideo} onClose={() => setShowVideo(null)} />
            </div>
        )
    }
}