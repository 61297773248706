import { Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState, useContext } from "react";
import useFetch, { host } from "../../../Utils/Fetchs";
import { UserContext } from "../../../contexts/UserContext";

export default function EstadisticasGenerales() {
    const [productos, setProductos] = useState([]);

    const { getFetch } = useFetch();

    const { planes } = useContext(UserContext);

    useEffect(() => {
        obtenerEstadisticas();

        const interval = setInterval(obtenerEstadisticas, 1000);

        return () => clearInterval(interval);
    }, []);

    function obtenerEstadisticas() {
        getFetch(host + "estadisticas/", true)
            .then(data => {
                setProductos(data.productos);
            }
            );
    }

    const columns = [
        { field: 'nombre', headerName: 'Nombre', flex: 1 },
        { field: 'estadisticas_interacciones', headerName: 'Interacciones', flex: 1 },
    ]

    if (planes.includes("1")) {
        columns.push({ field: 'estadisticas_agregados_al_carrito', headerName: 'Agregados al carrito', flex: 1 })
        columns.push({ field: 'estadisticas_quitados_del_carrito', headerName: 'Quitados del carrito', flex: 1 })
        columns.push({ field: 'estadisticas_pedidos', headerName: 'Pedidos', flex: 1 })
    }

    return (
        <Grid container sx={{
            height: "100%",
            width: "100%",
        }}>
            <DataGrid
                rows={productos}
                columns={columns}
                sx={{
                    height: "100%",
                    width: "100%",
                }}

            />
        </Grid>
    )
}