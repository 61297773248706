import useFetch, { host } from "./Fetchs";

export function useCategoria() {
    const { getFetch, postFetch, putFetch, deleteFetch } = useFetch();

    async function getCategorias(parms) {
        return getFetch(`${host}categories/${parms}/`);
    }

    async function getCategoriasABM() {
        return getFetch(`${host}abm/category/`, true);
    }

    async function postCategoria(nombre, tipoProducto) {
        return postFetch(`${host}abm/category/`, { name: nombre, tipoProducto: tipoProducto }, true);
    }

    async function putCategoria(id, nombre, tipoProducto) {
        return putFetch(`${host}abm/category/`, { id: id, name: nombre, tipoProducto: tipoProducto }, true);
    }

    async function deleteCategoria(id) {
        return deleteFetch(`${host}abm/category/`, { id: id }, true);
    }

    return { getCategorias, getCategoriasABM, postCategoria, putCategoria, deleteCategoria };
}