import React, { useEffect, useState, useRef } from "react";
import { Collapse, Drawer, Grid, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Link } from "react-router-dom";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

export default function SideBar({ open, onClose, rubros, setRubroSeleccionado, rubroSeleccionado }) {
    const handleClick = () => {
        onClose();
    };

    return (
        <Drawer anchor="left" open={open} onClose={onClose}>
            <Grid container flexDirection={"column"} sx={{
                pl: 4,
                pt: 2,
            }}>
                <Grid item>
                    <h2>Rubros</h2>
                </Grid>

                <List component="div" disablePadding>
                    {rubros.map(rubro => (
                        <ListItem button key={rubro.id} sx={{
                            backgroundColor: rubroSeleccionado && rubroSeleccionado.id === rubro.id ? 'rgba(0, 0, 0, 0.04)' : 'inherit'
                        }} onClick={() => { setRubroSeleccionado(rubro); handleClick(); }}>
                            <ListItemText primary={rubro.nombre} />
                        </ListItem>
                    ))}
                </List>
            </Grid>
        </Drawer>
    );
}