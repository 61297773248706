import React, { useState, useEffect, useContext } from 'react';
import { Grid, Tooltip, Button, Typography, Dialog, useMediaQuery, IconButton } from "@mui/material";
import { GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { esES } from "@mui/x-data-grid";
import CustomDataGrid from "../../../Components/ABM/MUIComponents/CustomDataGrid";
import SwitchIOS from "../../../Components/ABM/MUIComponents/SwitchIOS";
import DeleteIcon from '@mui/icons-material/Delete';
import useFetch, { host } from '../../../Utils/Fetchs';
import Detalles from './Detalles';
import BotonLoading from '../../../Components/ABM/MUIComponents/BotonLoading';

export default function ABMDetalles() {
    const [detalles, setDetalles] = useState([]);
    const [detalleSeleccionado, setDetalleSeleccionado] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [loading, setLoading] = useState(false);

    const { getFetch, deleteFetch } = useFetch();

    useEffect(() => {
        getFetch(`${host}detalles-producto/`, true)
            .then(data => { setDetalles(data); })
            .catch(err => console.error(err));
    }, []);

    function handleOpenModal(detalleSeleccionado) {
        if (openModal) {
            setDetalleSeleccionado(null);
            setOpenModal(false);
        } else {
            if (detalleSeleccionado) setDetalleSeleccionado(detalleSeleccionado);
            setOpenModal(true);
        }
    }

    function handleOpenDialogEliminar(row) {
        if (row) {
            setOpenDialog({
                open: true,
                action: 'eliminar',
                handleAction: () => {
                    setLoading(true);
                    deleteFetch(`${host}detalles-producto/`, { id: row.id }, true)
                        .then(() => {
                            setDetalles(detalles.filter(detalle => detalle.id !== row.id));
                        })
                        .finally(() => {
                            setOpenDialog({ open: false, action: '', handleAction: null });
                            setLoading(false);
                        });
                }
            });
        }
    }

    function handleCloseDialog(shouldExecuteAction) {
        if (shouldExecuteAction && openDialog.handleAction) {
            openDialog.handleAction();
        } else {
            setOpenDialog({ open: false });
        }
    }

    function CustomGridToolBar() {
        return (
            <GridToolbarContainer style={{ display: 'flex', alignItems: 'center', justifyContent: isMobile ? 'center' : 'space-between' }}>
                <Button variant="contained" color="primary" onClick={() => handleOpenModal()}>
                    Crear Detalle
                </Button>
                {!isMobile && (
                    <>
                        <GridToolbarExport />
                    </>
                )}
            </GridToolbarContainer>
        );
    }

    const columns = [
        { field: "nombre", headerName: "Nombre", flex: 1, minWidth: 120 },
        {
            field: "tipos", headerName: "Tipos", flex: 1, minWidth: 120,
            renderCell: (params) => {
                const tipos = params.row.tipos_producto?.map(tipo => tipo.nombre).join(', ');
                return tipos;
            }
        },
        {
            field: "Acciones",
            headerName: "Acciones",
            filterable: false,
            disableExport: true,
            sortable: false,
            flex: 1,
            maxWidth: 175,
            renderCell: (params) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => handleOpenModal(params.row)}
                        >
                            Editar
                        </Button>
                        <IconButton
                            onClick={() => handleOpenDialogEliminar(params.row)}
                            sx={{ marginLeft: '10px' }}
                        >
                            <DeleteIcon sx={{ color: 'red' }} />
                        </IconButton>
                    </div>
                );
            }
        }
    ];

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            style={{
                height: "100%",
                width: "100%",
            }}
            xs={12}
        >
            <Grid item xs={12}
                style={{ width: "100%", height: "100%" }}
            >
                <CustomDataGrid
                    rows={detalles}
                    columns={columns}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'codigo', sort: 'asc' }]
                        }
                    }}
                    pageSize={5}
                    disableRowSelectionOnClick
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    slots={{ toolbar: CustomGridToolBar }}
                    style={{ flexGrow: 1 }}
                />
            </Grid>
            <Dialog
                open={openDialog.open}
                onClose={() => setOpenDialog({ open: false })}
            >
                <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    style={{ gap: "20px", textAlign: "center", padding: "50px", }}
                >
                    <Typography
                        variant="h6"
                        component="div"
                        style={{
                            textAlign: "center"
                        }}
                    >
                        ¿Estás seguro que deseas {openDialog.action} el detalle?
                    </Typography>
                    <Grid
                        item
                        container
                        xs={12}
                        justifyContent="center"
                        style={{ gap: "30px" }}
                    >
                        <Grid container item xs={12} justifyContent="space-between" style={{ gap: "20px" }}>
                            <Button variant="contained" color={"primary"}
                                onClick={() => handleCloseDialog(false)}
                            >
                                Cancelar
                            </Button>
                            <BotonLoading
                                loading={loading}
                                funcion={() => handleCloseDialog(true)}
                                color={openDialog.action === 'activar' ? "green" : "red"}
                                width="100px"
                            >
                                {openDialog.action === 'eliminar' ? "Eliminar" : (openDialog.action === 'activar' ? "Activar" : "Desactivar")}
                            </BotonLoading>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
            <Detalles
                open={openModal}
                handleClose={handleOpenModal}
                tabla={detalleSeleccionado}
                setTabla={setDetalleSeleccionado}
                setDetalles={setDetalles}
                detalles={detalles}
            />
        </Grid>
    );
}