import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, Grid, TextField, Button, FormControlLabel, Checkbox, FormControl, FormGroup, Typography } from '@mui/material';
import useFetch, { host } from '../../../Utils/Fetchs';
import { useCategoria } from '../../../Utils/UCategoria';
import BotonLoading from '../../../Components/ABM/MUIComponents/BotonLoading';
import swal from 'sweetalert';

export default function ModalDescuentos({ open, onClose, descuentoSeleccionado, descuentos, setDescuentos }) {
    const [categorias, setCategorias] = useState([]);
    const [categoriasDescuento, setCategoriasDescuento] = useState([]);
    const [distintosProductos, setDistintosProductos] = useState(false);
    const [formData, setFormData] = useState({
        nombre: '',
        descripcion: '',
        cantidad_minima: '',
        porcentaje: '',
        categorias: []
    });

    const { getCategoriasABM } = useCategoria();

    const { postFetch, putFetch } = useFetch();

    useEffect(() => {
        getCategoriasABM()
            .then(response => setCategorias(response))
            .catch(error => console.error('Error al cargar categorías:', error));
    }, []);

    useEffect(() => {
        if (descuentoSeleccionado) {
            setCategoriasDescuento(descuentoSeleccionado.categorias.map(categoria => categoria.id));
            setDistintosProductos(descuentoSeleccionado.distintos_productos);
            setFormData({
                nombre: descuentoSeleccionado.nombre || '',
                descripcion: descuentoSeleccionado.descripcion || '',
                cantidad_minima: descuentoSeleccionado.cantidad_minima || '',
                distintos_productos: descuentoSeleccionado.distintos_productos,
                porcentaje: descuentoSeleccionado.porcentaje || '',
                categorias: descuentoSeleccionado.categorias.map(categoria => categoria.id) || []
            });
        } else {
            setCategoriasDescuento([]);
            setDistintosProductos(false);
            setFormData({
                nombre: '',
                descripcion: '',
                cantidad_minima: '',
                distintos_productos: false,
                porcentaje: '',
                categorias: []
            });
        }
    }, [descuentoSeleccionado]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = () => {
        const endpoint = `${host}descuentos/`;
        const method = descuentoSeleccionado ? putFetch : postFetch;
        const data = {
            ...formData,
            cantidad_minima: parseInt(formData.cantidad_minima),
            porcentaje: parseInt(formData.porcentaje),
            distintos_productos: distintosProductos,
            categorias: categoriasDescuento
        };
        if (descuentoSeleccionado) data.id = descuentoSeleccionado.id;

        if (!data.nombre || !data.descripcion || !data.cantidad_minima || !data.porcentaje || !data.categorias.length) {
            return swal('Error', 'Todos los campos son obligatorios', 'error');
        }

        method(endpoint, data, true)
            .then(response => {
                setDescuentos(prevDescuentos => { return descuentoSeleccionado ? prevDescuentos.map(descuento => descuento.id === response.descuento.id ? response.descuento : descuento) : [...prevDescuentos, response.descuento] });
                onClose();
            })
            .catch(err => console.log('Error al guardar el descuento:', err));
    };

    const handleCategoriasDescuento = (id, checked) => {
        if (checked) {
            setCategoriasDescuento(prevCategorias => [...prevCategorias, id]);
        } else {
            setCategoriasDescuento(prevCategorias => prevCategorias.filter(categoria => categoria !== id));
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>{descuentoSeleccionado ? 'Editar Descuento' : 'Crear Descuento'}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="nombre"
                    name="nombre"
                    label="Nombre del Descuento"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={formData.nombre}
                    onChange={handleChange}
                />
                <TextField
                    margin="dense"
                    id="descripcion"
                    name="descripcion"
                    helperText="Esta descripción se mostrará en todos los productos de esta categoria"
                    label="Descripción del Descuento"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={formData.descripcion}
                    onChange={handleChange}
                />
                <FormControl sx={{ margin: '0 auto 0 0' }} component="fieldset" variant="standard">
                    <Typography variant="h7"> Categorias </Typography>
                    <FormGroup>
                        {categorias?.map((categoria) => (
                            <FormControlLabel
                                key={categoria.id}
                                control={
                                    <Checkbox
                                        checked={categoriasDescuento.includes(categoria.id)}
                                        onChange={(event) => handleCategoriasDescuento(categoria.id, event.target.checked)}
                                        name={categoria.name}
                                    />
                                }
                                label={categoria.name}
                            />
                        ))}
                    </FormGroup>
                </FormControl>
                <TextField
                    margin="dense"
                    id="cantidad_minima"
                    name="cantidad_minima"
                    label="Cantidad Mínima de Productos"
                    fullWidth
                    variant="outlined"
                    value={formData.cantidad_minima}
                    onChange={(event) => {
                        if (!/^\d*$/.test(event.target.value)) return;
                        handleChange(event);
                    }}
                />
                <TextField
                    margin="dense"
                    id="porcentaje"
                    name="porcentaje"
                    label="Porcentaje de Descuento"
                    fullWidth
                    variant="outlined"
                    value={formData.porcentaje}
                    onChange={(event) => {
                        if (!/^\d*$/.test(event.target.value)) return;
                        handleChange(event);
                    }}
                    InputProps={{
                        startAdornment: '%'
                    }}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={distintosProductos}
                            onChange={() => setDistintosProductos(!distintosProductos)}
                        />
                    }
                    label='Permitir mezclar productos de la categoria'
                />
            </DialogContent>
            <Grid container justifyContent="space-between" p={1}>
                <Button onClick={onClose} color="primary" style={{ backgroundColor: 'red', color: 'white' }}>
                    Cancelar
                </Button>
                <BotonLoading
                    onClick={handleSubmit}
                    color="primary"
                    style={{ backgroundColor: 'green', color: 'white', width: '20%' }}
                    variant="contained"
                    loading={false}
                >
                    Guardar
                </BotonLoading>
            </Grid>
        </Dialog>
    );
}