import useFetch, { host } from "./Fetchs";

export function useAtuh() {
    const { postFetch } = useFetch();

    async function postLogin(data) {
        return postFetch(`${host}auth/`, data);
    }

    return { postLogin };
}