import React, { useState, useEffect, useContext } from 'react';
import { useProducto } from '../../../Utils/UProducto';
import { useCategoria } from '../../../Utils/UCategoria';
import { Grid, Button, Select, MenuItem, useMediaQuery, Autocomplete, TextField, Checkbox, FormControlLabel } from "@mui/material";
import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { esES } from "@mui/x-data-grid";
import CustomDataGrid from "../../../Components/ABM/MUIComponents/CustomDataGrid";
import AMModalPrecio from "./AMModalPrecio";
import useFetch, { host } from "../../../Utils/Fetchs";
import CustomizedSnackbars from '../../../Components/ABM/MUIComponents/CustomizedSnackbars';
import BotonLoading from '../../../Components/ABM/MUIComponents/BotonLoading';

export default function TablaPrecios() {
    const [productos, setProductos] = useState([]);
    const [productoSeleccionado, setproductoSeleccionado] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [categoriasNombres, setCategoriasNombres] = useState([]);
    const [categoriaFiltrada, setCategoriaFiltrada] = useState('');
    const { getCategoriasABM } = useCategoria();
    const { getProductos } = useProducto();
    const [porcentaje, setPorcentaje] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [textoAlert, setTextoAlert] = useState('');
    const [redondeo, setRedondeo] = useState(false);
    const [redondeoACentena, setRedondeoACentena] = useState(false);
    const [loading, setLoading] = useState(false);

    const { postFetch } = useFetch();

    useEffect(() => {
        getProductos()
            .then(response => {
                setProductos(response)
            })
            .catch(error => console.error('Error al cargar productos:', error));
    }, []);

    useEffect(() => {
        getCategoriasABM()
            .then(response => {
                const nombres = response.map(categoria => categoria.name);
                nombres.unshift("Todas");
                setCategoriasNombres(nombres);
            })
            .catch(error => console.error('Error al cargar categorias:', error));
    }, []);

    async function handlePostPrecios() {
        try {
            setLoading(true);
            let productosActualizados = [];

            if (porcentaje && categoriaFiltrada) {
                const productosFiltrados = productos.filter(producto => {
                    return categoriaFiltrada === "Todas" || producto.categorias.find(categoria => categoria.name === categoriaFiltrada);
                });

                productosActualizados = productosFiltrados.map(producto => {
                    const precioActualizado = (producto.precio * (1 + parseInt(porcentaje) / 100)).toFixed(2);
                    return { id: producto.id, precio: precioActualizado };
                });
            } else {
                productosActualizados = [...productos];
            }

            if (redondeo) {
                productosActualizados.forEach(producto => {
                    producto.precio = (Math.round(producto.precio / 10) * 10).toFixed(2);
                });
            } else if (redondeoACentena) {
                productosActualizados.forEach(producto => {
                    producto.precio = (Math.round(producto.precio / 100) * 100).toFixed(2);
                });
            }

            const data = { productos: productosActualizados };

            postFetch(`${host}precios/`,
                data,
            )
                .then(() => {
                    setPorcentaje('');
                    setCategoriaFiltrada('');
                    setRedondeo(false);
                    setRedondeoACentena(false);
                    const productosActualizadosLocal = productos.map(producto => {
                        const productoActualizado = productosActualizados.find(productoActualizado => productoActualizado.id === producto.id);
                        if (productoActualizado) {
                            return { ...producto, precio: parseFloat(productoActualizado.precio) };
                        } else {
                            return producto;
                        }
                    });
                    setProductos(productosActualizadosLocal);
                    setTextoAlert('Precios actualizados correctamente');
                    setSnackbarOpen(true);
                    setLoading(false);
                })
        } catch (error) {
            setTextoAlert('Error al actualizar los precios');
            setSnackbarOpen(true);
            setLoading(false);
        }
    }

    function handleOpenModal(producto) {
        if (openModal) {
            setproductoSeleccionado(null);
            setOpenModal(false);
        } else {
            if (producto) setproductoSeleccionado(producto);
            setOpenModal(true);
        }
    }

    function CustomGridToolBar() {
        return (
            <div style={{
                marginTop: isMobile ? '10px' : '0px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
                <GridToolbarContainer style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '15px',
                    flex: '1',
                }}>
                    <Autocomplete
                        value={categoriaFiltrada ? { label: categoriaFiltrada, value: categoriaFiltrada } : null}
                        options={categoriasNombres.map((option) => ({ label: option, value: option }))}
                        getOptionLabel={(option) => option.label}
                        filterOptions={(options, { inputValue }) =>
                            options.filter((option) => {
                                if (!inputValue) return true;
                                return option.label.toLowerCase().startsWith(inputValue.toLowerCase());
                            })
                        }
                        style={{ width: '250px' }}
                        onChange={(event, newValue) => {
                            if (newValue) {
                                setCategoriaFiltrada(newValue.value);
                            } else {
                                setCategoriaFiltrada('');
                            }
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Filtrar categoría" />
                        )}
                    />
                    <Autocomplete
                        value={porcentaje ? porcentaje.includes('%') ? porcentaje : porcentaje + '%' : ""}
                        options={[-15, -10, -5, 5, 10, 15].map((option) => ({ label: option + '%', value: option + '%' }))}
                        freeSolo
                        style={{ width: '150px' }}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                setPorcentaje(newValue);
                            } else if (newValue && newValue.inputValue) {
                                setPorcentaje(newValue.inputValue);
                            } else {
                                setPorcentaje(newValue ? newValue.value : '');
                            }
                        }}
                        onBlur={(event) => {
                            if (!event.target.value.includes('%')) {
                                setPorcentaje(event.target.value + '%');
                            }
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Porcentaje" />
                        )}
                    />
                    <Grid>
                        <BotonLoading
                            state={!(porcentaje && categoriaFiltrada) && !(!porcentaje && !categoriaFiltrada && (redondeo || redondeoACentena))} style={{ marginLeft: '20px', height: '40px' }}
                            funcion={handlePostPrecios}
                            loading={loading}
                        >
                            Aplicar
                        </BotonLoading>
                    </Grid>
                    <FormControlLabel
                        style={{ marginLeft: '20px' }}
                        checked={redondeo}
                        control={<Checkbox />}
                        onChange={() => { setRedondeo(!redondeo); setRedondeoACentena(false) }}
                        label="Redondear a decena"
                        labelPlacement="bottom"
                    />
                    <FormControlLabel
                        style={{ marginLeft: '20px' }}
                        checked={redondeoACentena}
                        control={<Checkbox />}
                        onChange={() => { setRedondeoACentena(!redondeoACentena); setRedondeo(false) }}
                        label="Redondear a centena"
                        labelPlacement="bottom"
                    />
                </GridToolbarContainer>
                {!isMobile && (
                    <div style={{
                        marginLeft: 'auto',
                        marginRight: '50px',
                    }}>
                        <GridToolbarExport />
                    </div>
                )}
            </div>
        )
    }

    let columns = [
        { field: "name", headerName: "Nombre", flex: 1, maxWidth: isMobile ? 120 : 750 },
        {
            field: "precio", headerName: "Precio", flex: 1, maxWidth: isMobile ? 115 : 750,
            renderCell: (params) => {
                return (
                    <div style={{ color: (redondeo || redondeoACentena || (porcentaje && categoriaFiltrada && (categoriaFiltrada === "Todas" || params.row.categorias.find(categoria => categoria.name === categoriaFiltrada)))) ? 'red' : "" }}>
                        {redondeo ? (porcentaje && categoriaFiltrada && (categoriaFiltrada === "Todas" ||
                            params.row.categorias.find(categoria => categoria.name === categoriaFiltrada))) ?
                            (Math.round((params.row.precio * (1 + parseInt(porcentaje) / 100)) / 10) * 10).toFixed(2) :
                            (Math.round(params.row.precio / 10) * 10).toFixed(2) :
                            redondeoACentena ? (porcentaje && categoriaFiltrada && (categoriaFiltrada === "Todas" ||
                                params.row.categorias.find(categoria => categoria.name === categoriaFiltrada))) ?
                                (Math.round((params.row.precio * (1 + parseInt(porcentaje) / 100)) / 100) * 100).toFixed(2) :
                                (Math.round(params.row.precio / 100) * 100).toFixed(2) :
                                (porcentaje && categoriaFiltrada && (categoriaFiltrada === "Todas" ||
                                    params.row.categorias.find(categoria => categoria.name === categoriaFiltrada))) ?
                                    (params.row.precio * (1 + parseInt(porcentaje) / 100)).toFixed(2) :
                                    params.row.precio.toFixed(2)}
                    </div>
                );
            }
        },
        {
            field: "categorias", headerName: "Categorias", flex: 1, filterable: false,
            sortable: false, minWidth: 140,
            renderCell: (params) => {
                return (
                    <Select
                        style={{ height: '30px', width: '150px' }}
                        displayEmpty
                    >
                        {params.row.categorias.map((categoria) => (
                            <MenuItem disabled style={{ opacity: 1 }}>{categoria.name}</MenuItem>
                        ))}
                    </Select>
                );
            }
        },
        {
            field: "Acciones",
            headerName: "Acciones",
            filterable: false,
            disableExport: true,
            sortable: false,
            flex: 1,
            maxWidth: 125,
            renderCell: (params) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => handleOpenModal(params.row)}
                        >
                            Editar
                        </Button>
                    </div>
                );
            }
        }
    ];

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            style={{
                height: "100%",
                width: "100%",
                scrollbarWidth: "thin",
                scrollbarColor: "transparent transparent",
            }}
        >
            <Grid item xs={12} style={{ width: "100%", height: "100%" }}>
                <CustomDataGrid
                    rows={productos}
                    columns={columns}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'codigo', sort: 'asc' }]
                        },
                        columns: isMobile && {
                            columnVisibilityModel: {
                                Acciones: false,
                            }

                        }
                    }}
                    pageSize={5}
                    disableRowSelectionOnClick
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    slots={{ toolbar: CustomGridToolBar }}
                    style={{ flexGrow: 1 }}
                />
            </Grid>
            <AMModalPrecio
                isOpen={openModal}
                onClose={handleOpenModal}
                producto={productoSeleccionado}
                productos={productos}
                setProductos={setProductos}
            />
            <CustomizedSnackbars
                texto={textoAlert}
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
            />
        </Grid>
    );
}