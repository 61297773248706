import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Button,
    Checkbox,
    FormGroup,
    FormControlLabel,
    ButtonGroup,
    Collapse,
    Typography,
    Grid,
} from '@mui/material';
import swal from 'sweetalert';

const FrameDetalles = ({ detalles, selectedDetalles, setSelectedDetalles, detallesProducto, setDetallesProducto, activeDetalle, setActiveDetalle, showButtons, setShowButtons }) => {
    const handleChangePrecio = (event, tipoNombre) => {
        if (!/^\d*$/.test(event.target.value)) return;
        const newDetallesProducto = [...detallesProducto];
        setDetallesProducto(newDetallesProducto.map((detalle) => {
            if (detalle.nombre === activeDetalle) {
                return {
                    ...detalle,
                    tipos_producto: detalle.tipos_producto.map((tipo) => {
                        if (tipo.nombre === tipoNombre) {
                            return {
                                ...tipo,
                                precio: parseInt(event.target.value),
                            };
                        }
                        return tipo;
                    }),
                };
            }
            return detalle;
        }));
    }

    const handleCheckboxChange = (event, detalleNombre) => {
        const newSelectedDetalles = { ...selectedDetalles };
        newSelectedDetalles[detalleNombre] = event.target.checked;
        const detalleCompleto = detalles.find((det) => det.nombre === detalleNombre);
        let actives = detalles.filter((det) => newSelectedDetalles[det.nombre]);
        if (event.target.checked) {
            if (!detalleCompleto?.precio_relativo && actives.filter((det) => det.nombre !== detalleNombre).some((det) => !det.precio_relativo)) {
                swal('Ya se ha seleccionado un detalle con precio no relativo');
                return;
            } else {
                setActiveDetalle(detalleNombre);
                setDetallesProducto([...detallesProducto, detalleCompleto]);
                setSelectedDetalles(newSelectedDetalles);
            }
        } else {
            setSelectedDetalles(newSelectedDetalles);
            setDetallesProducto(detallesProducto.filter((det) => det.nombre !== detalleNombre));
            actives = detalles.filter((det) => newSelectedDetalles[det.nombre]);
            if (actives.length === 0) {
                setActiveDetalle('');
                setShowButtons(false);
            } else {
                setActiveDetalle(actives[0].nombre);
            }
        }
        if (actives.length > 1) {
            setShowButtons(true);
        } else {
            setShowButtons(false);
        }
    }

    return (
        <div style={{ width: '100%' }}>
            {detalles.length > 0 &&
                <Typography variant="h7">Detalles</Typography>
            }
            <FormGroup row>
                {detalles?.map((detalle, index) => (
                    <FormControlLabel
                        key={index}
                        control={
                            <Checkbox
                                checked={selectedDetalles[detalle.nombre] || false}
                                onChange={(e) => handleCheckboxChange(e, detalle.nombre)}
                                name={detalle.nombre}
                            />
                        }
                        label={detalle.nombre}
                    />
                ))}
            </FormGroup>

            <Collapse in={showButtons} timeout={300}>
                <ButtonGroup variant="contained" style={{ marginTop: '20px', marginBottom: '20px' }}>
                    {detalles?.map((detalle, index) => (
                        selectedDetalles[detalle.nombre] && (
                            <Button
                                key={index}
                                onClick={() => setActiveDetalle(detalle.nombre)}
                                sx={{
                                    backgroundColor: detalle.nombre !== activeDetalle ? 'transparent' : undefined,
                                    boxShadow: detalle.nombre !== activeDetalle ? 'inset 0 0 10px #000' : undefined,
                                    color: detalle.nombre !== activeDetalle ? 'primary.main' : undefined,
                                    '&:hover': {
                                        backgroundColor: detalle.nombre !== activeDetalle ? 'rgba(0, 0, 0, 0.04)' : undefined,
                                    },
                                }}
                            >
                                {detalle.nombre}
                            </Button>
                        )
                    ))}
                </ButtonGroup>
            </Collapse>

            <Collapse in={activeDetalle} timeout={300}>
                {detalles?.map((detalle, detalleIndex) => (
                    selectedDetalles[detalle.nombre] && activeDetalle === detalle.nombre && (
                        <TableContainer component={Paper} key={detalleIndex}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={2} sx={{ textAlign: 'center', fontSize: '1em' }}>{detalle.nombre}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ width: 110 }}>Tipo</TableCell>
                                        <TableCell>Valor a Agregar</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {detalle.tipos_producto?.map((tipo, tipoIndex) => (
                                        <TableRow key={tipoIndex}>
                                            <TableCell>
                                                {tipo.nombre}
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    value={detallesProducto.find((detalle) => detalle.nombre === activeDetalle)?.tipos_producto[tipoIndex]?.precio || ''}
                                                    onChange={(e) => {
                                                        if (!/^\d*$/.test(e.target.value)) return;
                                                        handleChangePrecio(e, tipo.nombre)
                                                    }}
                                                    fullWidth
                                                    helperText={tipoIndex === 0 ?
                                                        detalle.precio_relativo ?
                                                            "Este valor se sumará al precio original del producto" : 'Este valor reemplazará al precio original del producto'
                                                        : ''
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                ))}
            </Collapse>
        </div>
    );
};

export default FrameDetalles;