import useFetch, { host } from "./Fetchs";

export function useProducto() {
    const { getFetch, deleteFetch, postProductsFetch, putProductsFetch, postProductsSinImagenFetch, putProductsSinImagenFetch } = useFetch();

    async function getProductos() {
        return getFetch(`${host}abm/product/`, true);
    }

    async function getProductosNombres() {
        return getFetch(`${host}utils/productos/`);
    }

    async function postProductsSinImagen(data) {
        return postProductsSinImagenFetch(`${host}abm/product/`, data, true);
    }

    async function putProductsSinImagen(data) {
        return putProductsSinImagenFetch(`${host}abm/product/`, data, true);
    }

    async function postProducto(data) {
        return postProductsFetch(`${host}abm/product/`, data, true);
    }

    async function putProducto(data) {
        return putProductsFetch(`${host}abm/product/`, data, true);
    }

    async function deleteProducto(id) {
        return deleteFetch(`${host}abm/product/`, { id: id }, true);
    }

    return { getProductos, getProductosNombres, postProducto, putProducto, deleteProducto, postProductsSinImagen, putProductsSinImagen };
}

