import { createContext, useState, useEffect } from 'react';

const UserContext = createContext()

function UserProvider({ children }) {
    const [token, setToken] = useState(undefined);
    const [empresas, setEmpresas] = useState([]);
    const [empresaActual, setEmpresaActual] = useState();
    const [tipo, setTipo] = useState();
    const [planes, setPlanes] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        const empresaActual = localStorage.getItem('empresaActual');
        const tipo = localStorage.getItem('tipo');
        setToken(token);
        setTipo(tipo);
        setEmpresaActual({ "nombre": empresaActual });
    }, []);

    function getToken() {
        if (token) return token;
        return localStorage.getItem('token');
    }

    function login(respuesta) {
        setToken(respuesta.token);
        setEmpresaActual({ "nombre": respuesta.empresa });
        setTipo(respuesta.tipo);
        localStorage.setItem('token', respuesta.token);
        localStorage.setItem('empresaActual', respuesta.empresa);
        localStorage.setItem('tipo', respuesta.tipo);
    }

    function logout() {
        setToken(null);
        localStorage.removeItem('token');
        localStorage.removeItem('empresaActual');
        localStorage.removeItem('tipo');
    }

    return (
        <UserContext.Provider value={{ token, login, logout, empresas, empresaActual, tipo, setPlanes, planes, getToken }}>
            {children}
        </UserContext.Provider>
    );
};

export { UserProvider, UserContext };