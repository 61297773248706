import QRCode from 'react-qr-code';
import { Button, Typography } from "@mui/material";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";

export default function QrPage() {
    const { empresaActual } = useContext(UserContext);
    const websiteUrl = `https://karta.com.ar/${empresaActual?.nombre}/qr`;

    const handlePrint = () => {
        window.print();
    };

    return (
        <div style={{
            display: 'flex', flexDirection: 'column', alignItems: 'center',
            justifyContent: 'center', height: '100vh', overflowX: 'hidden', overflowY: 'hidden'
        }}>
            <QRCode value={websiteUrl} className="printable" />
            <Typography style={{ marginTop: '20px' }} className="printable">{empresaActual?.nombre}</Typography>
            <Button style={{ marginTop: '50px' }} variant="contained" onClick={handlePrint}>Imprimir QR</Button>

            <style>
                {`
                    @media print {
                        body * {
                            visibility: hidden;
                        }
                        .printable, .printable * {
                            visibility: visible;
                        }
                    }
                `}
            </style>
        </div>
    );
}
