import React, { useContext } from 'react';
import { SettingsContext } from '../../../../contexts/SettingsContext';
import { useMediaQuery } from '@mui/material';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import { IconButton } from '@mui/material';
import useFetch, { host } from "../../../../Utils/Fetchs";
import SeparadorMiles from '../../../../Utils/SeparadorMiles';


export default function FrontCard(props) {
    const { producto, settings, funcion } = props;
    const isMobile = useMediaQuery('(max-width: 450px)');

    const { postFetch } = useFetch();

    const { carrito, setCarrito, planes, setCarritoDrawer, addItem, decreaseItem, isInCarrito } = useContext(SettingsContext);

    return (
        <div style={{
            flexGrow: 1,
            height: '100%',
            background: "red",
            width: '100%',
            backgroundColor: settings?.background_color2,
            padding: '0 0 10px 0',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
        }}
        >
            <div style={{
                width: '100%',
                aspectRatio: '4/3',
                cursor: 'pointer',
                overflow: 'hidden',
            }}
                onClick={funcion}
            >
                {producto?.Imagen !== null ?
                    <div style={{
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                    }}>
                        <img src={host + producto?.Imagen} alt={producto?.name} style={{
                            width: '100%',
                            height: 'auto',
                            objectFit: 'cover',
                            position: 'relative',
                            zIndex: 1,
                        }}
                        />
                    </div>
                    :
                    <div style={{
                        color: settings?.color3,
                        margin: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%'
                    }}>
                        {settings.tipo === 'Carta' ?
                            <FastfoodIcon style={{ fontSize: '700%' }} /> :
                            <ShoppingBagIcon style={{ fontSize: '700%' }} />
                        }
                    </div>
                }
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: '0 10px',
                gap: '10px',
                height: '130px',
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    gap: '10px',
                    alignItems: 'center',

                }}
                    onClick={funcion}
                >
                    <p style={{
                        fontSize: isMobile ? '0.9em' : '1em',
                        fontWeight: 'semibold',
                        color: settings?.color2,
                        fontFamily: settings?.font_family,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '3',
                        WebkitBoxOrient: 'vertical',
                    }}>
                        {producto?.Nombre}
                    </p>
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                    flexWrap: "wrap",
                    height: "100%",
                    gap: "10px",
                }}>
                    {producto?.Precio ?
                        <p style={{
                            color: settings?.color2, fontFamily: settings?.font_family,
                            textAlign: "right",
                            width: "100%",
                        }}> ${SeparadorMiles(producto?.Precio)}</p>
                        :
                        null
                    }
                    {planes.includes('1') ?
                        producto?.detalles?.length > 0 ?
                            <button
                                style={{
                                    backgroundColor: settings?.background_color3,
                                    borderRadius: '25px',
                                    color: settings?.color3,
                                    border: 'none',
                                    fontSize: '1em',
                                    cursor: 'pointer',
                                    fontFamily: settings?.font_family,
                                    padding: "0 20px",
                                    height: '35px',
                                    textAlign: 'center',
                                    alignContent: 'center',
                                }}
                                onClick={() => {
                                    funcion();
                                }}
                            >
                                Agregar
                            </button>
                            :
                            isInCarrito(producto) !== -1 ?
                                <div
                                    style={{
                                        borderRadius: '25px',
                                        height: '35px',
                                        border: 'none',
                                        fontSize: '24px',
                                        fontFamily: settings?.font_family,
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        overflow: 'hidden',
                                        gap: '10px',
                                    }}>
                                    <IconButton style={{
                                        cursor: 'pointer',
                                        width: '30%',
                                        height: '100%',
                                        textAlign: 'center',
                                        alignContent: 'center',
                                        color: settings?.color2,
                                    }}
                                        onClick={() => {
                                            decreaseItem(producto);
                                        }}>
                                        -
                                    </IconButton>
                                    <p style={{
                                        fontSize: '20px',
                                        color: settings?.color2,
                                    }}>{carrito.find(item => item.id === producto.id)?.cantidad}</p>
                                    <IconButton
                                        style={{
                                            cursor: 'pointer',
                                            width: '30%',
                                            height: '100%',
                                            textAlign: 'center',
                                            alignContent: 'center',
                                            color: settings?.color2,
                                        }}
                                        onClick={() => {
                                            addItem(producto);
                                        }}>
                                        +
                                    </IconButton>
                                </div>
                                :
                                <button
                                    style={{
                                        backgroundColor: settings?.background_color3,
                                        borderRadius: '25px',
                                        color: settings?.color3,
                                        border: 'none',
                                        fontSize: '1em',
                                        cursor: 'pointer',
                                        fontFamily: settings?.font_family,
                                        height: '35px',
                                        textAlign: 'center',
                                        alignContent: 'center',
                                        padding: "0 20px",
                                    }}
                                    onClick={() => {
                                        if (carrito.find(item => item.id === producto.id)) {
                                            setCarrito(carrito.filter(item => item.id !== producto.id));

                                        } else {
                                            setCarrito([...carrito, { ...producto, cantidad: 1 }]);
                                            postFetch(host + 'estadisticas/agregar-al-carrito/', {
                                                id: producto.id,
                                            })
                                        }
                                    }}
                                >
                                    Agregar
                                </button>
                        :
                        <button
                            style={{
                                backgroundColor: settings?.background_color3,
                                borderRadius: '25px',
                                width: "70%",
                                height: '35px',
                                color: settings?.color3,
                                border: 'none',
                                fontSize: '1em',
                                cursor: 'pointer',
                                fontFamily: settings?.font_family,
                                whiteSpace: 'nowrap',

                            }}
                            onClick={
                                funcion
                            }
                        >
                            {settings.id === 11 ? 'CLICK HERE' : 'Ver más'}
                        </button>
                    }
                </div>
            </div >
        </div >
    )
}