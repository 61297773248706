import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useContext } from "react";
import { SettingsContext } from '../../contexts/SettingsContext';

export default function QrRedirect() {
    const navigate = useNavigate();
    const { getEmpresa } = useContext(SettingsContext);

    useEffect(() => {
        let redirectLink = `/${getEmpresa()}/`;
        navigate(redirectLink);
    }, [getEmpresa, navigate]);

    return null;
};