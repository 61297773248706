import { Routes, Route } from "react-router-dom";
import QrRedirect from "../pages/qrRedirect/QrRedirect";
import CartaPage from "../pages/cartaPage/CartaPage";
import FinalizarCompra from "../pages/cartaPage/components/FinalizarCompra";

export const paginas = [
    {
        url: "/",
        component: <CartaPage />
    },
    {
        url: "/qr/",
        component: <QrRedirect />
    },
    {
        url: "/finalizarCompra/",
        component: <FinalizarCompra />
    },
    {
        url: "/*",
        component: <h1>404</h1>
    },

]

export default function PublicDashboard() {
    return (
        <Routes>
            {paginas.map((pagina, index) => (
                <Route key={index} path={pagina.url} element={pagina.component} />
            ))}
        </Routes>
    )
}