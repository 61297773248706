import { Box, Grid, Paper, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import useFetch, { host } from "../../../Utils/Fetchs";
import { PieChart } from '@mui/x-charts/PieChart';
import { UserContext } from "../../../contexts/UserContext";


export default function EstadisticasGenerales() {
    const [productos, setProductos] = useState([]);

    const { getFetch } = useFetch();

    const { planes } = useContext(UserContext);

    useEffect(() => {
        obtenerEstadisticas();

        const interval = setInterval(obtenerEstadisticas, 1000);

        return () => clearInterval(interval);
    }, []);

    function obtenerEstadisticas() {
        getFetch(host + "estadisticas/", true)
            .then(data => {
                setProductos(data.productos);
            }
            );
    }


    const dataAgregadosAlCarrito = productos.sort((a, b) => b.estadisticas_agregados_al_carrito - a.estadisticas_agregados_al_carrito).slice(0, 3).filter(p => p.estadisticas_agregados_al_carrito > 0).map(producto => {
        return {
            id: producto.id,
            label: producto.nombre + " (" + producto.estadisticas_agregados_al_carrito + ")",
            value: producto.estadisticas_agregados_al_carrito,
            estadisticas_quitados_del_carrito: producto.estadisticas_quitados_del_carrito,
        }
    })

    const dataQuitadosDelCarrito = productos.sort((a, b) => b.estadisticas_quitados_del_carrito - a.estadisticas_quitados_del_carrito).slice(0, 3).filter(p => p.estadisticas_quitados_del_carrito > 0).map(producto => {
        return {
            id: producto.id,
            label: producto.nombre + " (" + producto.estadisticas_quitados_del_carrito + ")",
            value: producto.estadisticas_quitados_del_carrito,
            estadisticas_agregados_al_carrito: producto.estadisticas_agregados_al_carrito,
        }
    })

    const dataInteracciones = productos.sort((a, b) => b.estadisticas_interacciones - a.estadisticas_interacciones).slice(0, 3).filter(p => p.estadisticas_interacciones > 0).map(producto => {
        return {
            id: producto.id,
            label: producto.nombre + " (" + producto.estadisticas_interacciones + ")",
            value: producto.estadisticas_interacciones,
        }
    })

    const dataPedidos = productos.sort((a, b) => b.estadisticas_pedidos - a.estadisticas_pedidos).slice(0, 3).filter(p => p.estadisticas_pedidos > 0).map(producto => {
        return {
            id: producto.id,
            label: producto.nombre + " (" + producto.estadisticas_pedidos + ")",
            value: producto.estadisticas_pedidos,
        }
    })

    const datas = [
        { titulo: "Más interacciones", data: dataInteracciones },
    ]

    if (planes.includes("1")) {
        datas.push({ titulo: "Más quitados del carrito", data: dataQuitadosDelCarrito });
        datas.push({ titulo: "Más agregados al carrito", data: dataAgregadosAlCarrito });
        datas.push({ titulo: "Más pedidos", data: dataPedidos });
    }

    return (
        <Grid container sx={{
            paddingTop: 2,
            paddingInline: 2,
        }}
            spacing={3}
        >
            {datas.map((data, index) => (
                <Grid item container xs={6} key={index} sx={{
                    height: 400,
                }}>
                    <Paper key={index} sx={{
                        padding: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%',
                    }}>
                        <Typography variant="h6" sx={{ marginBottom: 2 }}>{data.titulo}</Typography>
                        <PieChart
                            series={[
                                {
                                    data: data.data,
                                    highlightScope: { faded: 'global', highlighted: 'item' },
                                    faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                                },
                            ]}

                        />
                    </Paper>
                </Grid>
            ))}
        </Grid >
    )
}