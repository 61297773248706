import React, { useState, useEffect, useContext } from 'react';
import { useCategoria } from '../../../Utils/UCategoria';
import { Grid, Tooltip, Button, Typography, Dialog, useMediaQuery, IconButton } from "@mui/material";
import { GridToolbarContainer, GridToolbarFilterButton } from "@mui/x-data-grid";
import { esES } from "@mui/x-data-grid";
import CustomDataGrid from "../../../Components/ABM/MUIComponents/CustomDataGrid";
import AMModalCategorias from './AMModalCategorias';
import SwitchIOS from "../../../Components/ABM/MUIComponents/SwitchIOS";
import DeleteIcon from '@mui/icons-material/Delete';
import useFetch, { host } from '../../../Utils/Fetchs';
import BotonLoading from '../../../Components/ABM/MUIComponents/BotonLoading';

export default function ABMCategorias() {
  const [categorias, setCategorias] = useState([]);
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');
  const [loading, setLoading] = useState(false);

  const { postFetch } = useFetch();
  const { getCategoriasABM, deleteCategoria } = useCategoria();

  useEffect(() => {
    getCategoriasABM()
      .then(response => setCategorias(response))
      .catch(error => console.error('Error al cargar categorías:', error));
  }, []);

  function handleOpenModal(categoria) {
    if (openModal) {
      setCategoriaSeleccionada(null);
      setOpenModal(false);
    } else {
      if (categoria) setCategoriaSeleccionada(categoria);
      setOpenModal(true);
    }
  }

  function handleIrOrdenar() {
    window.location.href = '/admin/categorias/ordenar';
  }

  function handleOpenDialog(row) {
    if (row) {
      setCategoriaSeleccionada(row);
      setOpenDialog({
        open: true,
        action: row.delete ? 'activar' : 'desactivar',
        handleAction: () => {
          setLoading(true);
          deleteCategoria(row.id)
            .then(() => {
              setCategorias(categorias.map(categoria => {
                if (categoria.id === row.id) {
                  categoria.delete = !categoria.delete;
                }
                return categoria;
              }));
            })
            .finally(() => {
              setCategoriaSeleccionada(null);
              setOpenDialog({ open: false, action: '', handleAction: null });
              setLoading(false);
            });
        }
      });
    }
  }

  function handleOpenDialogEliminar(row) {
    if (row) {
      setCategoriaSeleccionada(row);
      setOpenDialog({
        open: true,
        action: 'eliminar',
        handleAction: () => {
          setLoading(true);
          postFetch(`${host}categorias/borrar/`, { id: row.id }, true)
            .then(() => {
              setCategorias(categorias.filter(categoria => categoria.id !== row.id));
            })
            .finally(() => {
              setCategoriaSeleccionada(null);
              setOpenDialog({ open: false, action: '', handleAction: null });
              setLoading(false);
            });
        }
      });
    }
  }

  function handleCloseDialog(shouldExecuteAction) {
    if (shouldExecuteAction && openDialog.handleAction) {
      openDialog.handleAction();
    } else {
      setCategoriaSeleccionada(null);
      setOpenDialog({ open: false });
    }
  }

  function CustomGridToolBar() {
    return (
      <GridToolbarContainer style={{ display: 'flex', alignItems: 'center', justifyContent: isMobile ? 'center' : 'space-between' }}>
        <Button variant="contained" color="primary" onClick={() => handleOpenModal()}>
          Crear Categoria
        </Button>
        {!isMobile && (
          <>
            <GridToolbarFilterButton />
            <Button variant="contained" color="primary" onClick={() => handleIrOrdenar()}>
              Reordenar Categorias
            </Button>
          </>
        )}
      </GridToolbarContainer>
    );
  }

  const columns = [
    { field: "name", headerName: "Nombre", flex: 1, minWidth: 120 },
    {
      field: "delete", headerName: "Activa", flex: 1,
      renderCell: (params) =>
        <Tooltip title={params.row.delete ? "Recuperar" : "Eliminar"}>
          <SwitchIOS
            onChange={() => handleOpenDialog(params.row)}
            checked={!params.row.delete}
          />
        </Tooltip>
    },
    {
      field: "Acciones",
      headerName: "Acciones",
      filterable: false,
      disableExport: true,
      sortable: false,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleOpenModal(params.row)}
            >
              Editar
            </Button>
            <IconButton
              onClick={() => handleOpenDialogEliminar(params.row)}
              sx={{ marginLeft: '10px' }}
            >
              <DeleteIcon sx={{ color: 'red' }} />
            </IconButton>
          </div>
        );
      }
    }
  ];

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      style={{
        height: "100%",
        width: "100%",
      }}
      xs={12}
    >
      <Grid item xs={12}
        style={{ width: "100%", height: "100%" }}
      >
        <CustomDataGrid
          rows={categorias}
          columns={columns}
          initialState={{
            sorting: {
              sortModel: [{ field: 'codigo', sort: 'asc' }]
            }
          }}
          pageSize={5}
          disableRowSelectionOnClick
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          slots={{ toolbar: CustomGridToolBar }}
          style={{ flexGrow: 1 }}
        />
      </Grid>
      <Dialog
        open={openDialog.open}
        onClose={() => setOpenDialog({ open: false })}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          style={{ gap: "20px", textAlign: "center", padding: "50px", }}
        >
          <Typography
            variant="h6"
            component="div"
            style={{
              textAlign: "center"
            }}
          >
            ¿Estás seguro que deseas {openDialog.action} la categoria?
          </Typography>
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            style={{ gap: "30px" }}
          >
            <Grid container item xs={12} justifyContent="space-between" style={{ gap: "20px" }}>
              <Button variant="contained" color={"primary"}
                onClick={() => handleCloseDialog(false)}
              >
                Cancelar
              </Button>
              <BotonLoading
                loading={loading}
                funcion={() => handleCloseDialog(true)}
                color={openDialog.action === 'activar' ? "green" : "red"}
                width="100px"
              >
                {openDialog.action === 'eliminar' ? "Eliminar" : (openDialog.action === 'activar' ? "Activar" : "Desactivar")}
              </BotonLoading>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
      <AMModalCategorias
        isOpen={openModal}
        onClose={handleOpenModal}
        categoria={categoriaSeleccionada}
        categorias={categorias}
        setCategorias={setCategorias}
      />
    </Grid>
  );
}