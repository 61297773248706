import React, { useEffect, useState } from 'react';
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, FormControlLabel, TableRow, TextField, Modal, Box, Typography, IconButton, Checkbox, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import useFetch, { host } from '../../../Utils/Fetchs';
import BotonLoading from '../../../Components/ABM/MUIComponents/BotonLoading';

export default function Detalles(props) {
    const { tabla, setTabla, open, handleClose, detalles, setDetalles } = props;
    const [nombre, setNombre] = useState("");
    const [relativo, setRelativo] = useState(false);
    const [porcentual, setPorcentual] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tipos, setTipos] = useState([
        { id: 1, nombre: "", precio: "" },
        { id: 2, nombre: "", precio: "" }
    ]);
    const [lastId, setLastId] = useState(2);

    const { postFetch, putFetch } = useFetch();

    useEffect(() => {
        if (tabla?.id) {
            setNombre(tabla.nombre);
            setRelativo(tabla.precio_relativo);
            setPorcentual(tabla.precio_porcentual);
            setTipos(tabla.tipos_producto);
            setLastId(tabla.tipos_producto.map(t => t.id).reduce((a, b) => Math.max(a, b)));
        } else {
            setNombre("");
            setRelativo(false);
            setPorcentual(false);
            setTipos([
                { id: 1, nombre: "", precio: "" },
                { id: 2, nombre: "", precio: "" }
            ]);
            setLastId(2);
        }
    }, [tabla]);

    const agregarDetalle = () => {
        const newId = lastId + 1;
        setTipos([...tipos, { id: newId, nombre: "", precio: "" }]);
        setLastId(newId);
    };

    const quitarDetalle = (detalleId) => {
        if (tipos.length > 2) {
            setTipos(tipos.filter(d => d.id !== detalleId));
        }
    };

    const handleGuardarDetalle = () => {
        const tiposFiltrados = tipos.filter(d => d !== "");
        const tiposSinID = tiposFiltrados.map(t => ({ nombre: t.nombre, precio: t.precio }));
        const data = { nombre: nombre, plantillas_tipo_producto: tiposSinID, precio_porcentual: porcentual, precio_relativo: relativo };
        setLoading(true);
        postFetch(`${host}detalles-producto/`, data, true)
            .then(response => {
                setDetalles([...detalles, response]);
                handleClose();
            })
            .catch(err => console.error(err))
            .finally(() => { setLoading(false); handleClose(); });
    };

    const handleEditarDetalle = async () => {
        const tiposFiltrados = tipos.filter(t => t.nombre !== "");
        const tiposFormato = tiposFiltrados.map(t => {
            if (t.id && tabla.tipos_producto.some(tp => tp.id === t.id)) {
                return { id: t.id, nombre: t.nombre, precio: t.precio === '' ? null : t.precio };
            } else {
                return { nombre: t.nombre, precio: t.precio === '' ? null : t.precio };
            }
        });
        let data = { id: tabla.id };

        if (nombre !== tabla.nombre) {
            data = { ...data, nombre: nombre };
        }

        if (tiposFormato.length > 0) {
            data = { ...data, plantillas_tipo_producto: tiposFormato };
        }

        if (porcentual !== tabla.precio_porcentual) {
            data = { ...data, precio_porcentual: porcentual };
        }

        if (relativo !== tabla.precio_relativo) {
            data = { ...data, precio_relativo: relativo };
        }

        console.log(data);

        if (data.nombre || data.plantillas_tipo_producto) {
            setLoading(true);
            putFetch(`${host}detalles-producto/`, data, true)
                .then(response => {
                    console.log(response);
                    setDetalles(detalles.map(d => {
                        if (d.id === response.id) {
                            return response;
                        }
                        return d;
                    }));
                })
                .catch(err => console.error(err))
                .finally(() => { setLoading(false); handleClose(); });
        }
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        height: '80%',
        borderRadius: '8px',
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
    };

    function handleCloseModal() {
        setNombre("");
        setTipos([
            { id: 1, nombre: "", precio: "" },
            { id: 2, nombre: "", precio: "" }
        ]);
        setTabla(null);
        setLastId(2);
        handleClose();
    }

    return (
        <Modal
            open={open}
            onClose={handleCloseModal}
        >
            <Box sx={{ ...modalStyle, overflow: 'hidden' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h5" component="h2" fontWeight="bold">
                        Detalle
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Grid container spacing={2} sx={{ marginBottom: 2, marginLeft: 2 }}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Nombre"
                            value={nombre}
                            fullWidth
                            onChange={(event) => setNombre(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container direction="column" spacing={2} marginLeft={5}>
                            <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={relativo}
                                            onChange={(e) => setRelativo(!relativo)}
                                        />
                                    }
                                    label="Relativo"
                                />
                                <Tooltip
                                    title={
                                        <Typography variant="body2">
                                            Los valores relativos, si se activan, hacen que los valores ingresados en cada tipo se sumen o resten como enteros al precio general del producto. Por ejemplo, si se ingresa "200" y el precio del producto es de 1000, el precio total del producto con el detalle sería de 1200.
                                        </Typography>
                                    }
                                    arrow
                                >
                                    <IconButton size="small">
                                        <HelpOutlineIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={porcentual}
                                            onChange={(e) => setPorcentual(!porcentual)}
                                        />
                                    }
                                    label="Porcentual"
                                />
                                <Tooltip
                                    title={
                                        <Typography variant="body2">
                                            Los valores porcentuales, si se activan, permiten que los valores ingresados en cada tipo del producto funcionen como un porcentaje del precio general del producto. Por ejemplo, si se ingresa "20", se sumará un 20% del precio total del producto.
                                        </Typography>
                                    }
                                    arrow
                                >
                                    <IconButton size="small">
                                        <HelpOutlineIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}>
                    <TableContainer component={Paper} sx={{ flexGrow: 1, overflowY: 'auto' }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nombre</TableCell>
                                    <TableCell>Precio predeterminado</TableCell>
                                    <TableCell align="center">Borrar</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tipos?.map((detalle, index) => (
                                    <TableRow key={detalle.id}>
                                        <TableCell>
                                            <TextField
                                                fullWidth
                                                value={detalle.nombre}
                                                disabled={nombre === ""}
                                                placeholder={index === 0 ? "Negro" : index === 1 ? "Blanco" : ""}
                                                onChange={(event) => {
                                                    setTipos(tipos.map(d => {
                                                        if (d.id === detalle.id) {
                                                            return { ...d, nombre: event.target.value };
                                                        }
                                                        return d;
                                                    }));

                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                fullWidth
                                                value={detalle.precio}
                                                disabled={nombre === ""}
                                                onChange={(event) => {
                                                    if (!/^\d*$/.test(event.target.value)) return;

                                                    setTipos(tipos.map(d => {
                                                        if (d.id === detalle.id) {
                                                            return { ...d, precio: event.target.value };
                                                        }
                                                        return d;
                                                    }));
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <IconButton
                                                onClick={() => quitarDetalle(detalle.id)}
                                                disabled={tipos.length <= 2}
                                            >
                                                <DeleteIcon color={tipos.length > 2 ? "error" : "disabled"} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box sx={{ display: 'flex', marginTop: '20px', justifyContent: 'space-between' }}>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            setTabla(null);
                            handleCloseModal();
                        }}
                    >
                        {nombre === "" ? "Cancelar" : "Descartar Cambios"}
                    </Button>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={agregarDetalle}
                            sx={{ width: '250px' }}
                            disabled={nombre === ""}
                        >
                            Agregar Fila
                        </Button>
                        <BotonLoading
                            funcion={tabla?.id ? handleEditarDetalle : handleGuardarDetalle}
                            color='green'
                            loading={loading}
                            state={nombre === "" || tipos.some(t => t.nombre === "")}
                        >
                            {tabla?.id ? "Editar" : "Guardar"}
                        </BotonLoading>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}