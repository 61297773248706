import { Box, Grid, IconButton, Typography, TextField, Collapse, useMediaQuery } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import React from 'react';
import { SettingsContext } from "../../../contexts/SettingsContext";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Modal from '@mui/material/Modal';
import useFetch, { host } from "../../../Utils/Fetchs";
import { useNavigate } from "react-router-dom";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StorefrontIcon from '@mui/icons-material/Storefront';
import Slide from '@mui/material/Slide';

export default function Carrito() {
    const { settings, carrito, setCarrito, getEmpresa, planes, setCarritoDrawer, addItem, decreaseItem, getPrecioTotal } = useContext(SettingsContext);
    const navigate = useNavigate();

    const { postFetch } = useFetch();

    const [open, setOpen] = useState(false);

    const [nombre, setNombre] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [isDelivery, setIsDelivery] = useState(null);
    const [barrio, setBarrio] = useState('');
    const [direccion, setDireccion] = useState('');
    const [telefono, setTelefono] = useState(null);
    const [error, setError] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');

    function handleFinalizarCompra() {
        navigate("finalizarCompra/");
    }

    function handleOpenModal() {
        setOpen(true);
    }

    function getDetalle(item) {
        let detalles = []

        if (item.detalles) {
            item.detalles.forEach(detalle => {
                detalle.tipos.map(tipo => {
                    if (tipo.selected) {
                        detalles.push(tipo.nombre)
                    }
                })
            })
        }

        return detalles.length > 0 ? `( ${detalles.join(' - ')} )` : ''
    }

    function postOrder() {
        let postData = {
            empresa: getEmpresa(),
            items: carrito.map(item => {
                return {
                    producto: item.id,
                    cantidad: item.cantidad
                }
            }),
            nombre: nombre,
            especificacion: descripcion,
            telefono: telefono,
            barrio: barrio,
            direccion: direccion
        }

        postFetch(host + 'pedidos/', postData)
    }

    function makeOrder() {
        if (planes.includes('2')) postOrder()

        let wppMessage = `Nombre: *${nombre}*\n\n`

        carrito.forEach((item, index) => {
            wppMessage += `${index + 1}. *${item.Nombre}* x *${item.cantidad}* = $${getPrecioTotal(item, item.detalles) * item.cantidad}\n`
        })

        if (descripcion) wppMessage += `\nEspecificaciones: ${descripcion}\n\n`

        if (isDelivery) {
            wppMessage += `Barrio: ${barrio.toUpperCase()}\n`
            wppMessage += `Dirección: ${direccion}\n\n`
        } else {
            wppMessage += `Retiro en local\n\n`
        }

        wppMessage += `Total: $${carrito.reduce((acc, item) => acc + getPrecioTotal(item, item.detalles) * item.cantidad, 0)}`

        console.log(wppMessage);
        window.open(`https://wa.me/${settings.telefono}?text=${encodeURIComponent(wppMessage)}`, '_blank');

        setCarritoDrawer(false);
        setCarrito([]);
        setOpen(false);
        setNombre('');
        setDescripcion('');
        setBarrio('');
        setDireccion('');
        setTelefono('');
        setIsDelivery();
        setError(false);
    }

    const handleConfirmOrder = () => {
        if (nombre && telefono && isDelivery !== null && (isDelivery ? direccion && barrio : true)) {
            makeOrder();
        } else {
            setError(true);
        }
    };

    useEffect(() => {
        setError(false);
    }, [nombre, telefono, barrio, direccion, descripcion, isDelivery]);

    return (
        <Box sx={{ position: 'relative' }}>
            <Box sx={{
                backgroundColor: settings?.background_color1,
                maxHeight: '80vh',
                overflowY: 'auto',
                padding: '20px',
                paddingY: '50px',
                paddingX: isMobile ? '' : '20%',
                '&::-webkit-scrollbar': {
                    width: '10px',
                },
                '&::-webkit-scrollbar-track': {
                    background: settings?.background_color2,
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: settings?.background_color3,
                    borderRadius: '20px',
                },
            }} >
                <Typography variant="h4" sx={{ color: settings?.color1 }}>Carrito</Typography>

                {carrito.map((item, index) => (
                    <Grid container key={index} sx={{
                        flexWrap: 'nowrap',
                        padding: '10px',
                        borderBottom: '1px solid #ccc',
                        flexDirection: "column",
                        gap: "15px"
                    }}>
                        <Typography variant="h6" sx={{ color: settings?.color1 }}>
                            {item.Nombre} {getDetalle(item)}
                        </Typography>
                        <Grid container item sx={{
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                            <Typography variant="h6" sx={{ color: settings?.color1 }}>$ {getPrecioTotal(item, item.detalles) * item.cantidad}</Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    padding: '5px',
                                    backgroundColor: settings?.background_color3,
                                    borderRadius: '5px',
                                    width: "150px"
                                }}>
                                <IconButton
                                    onClick={() => {
                                        decreaseItem(item);
                                    }}
                                    sx={{ color: settings?.color3 }}>
                                    <RemoveIcon />
                                </IconButton>
                                <Typography variant="h6" sx={{ color: settings?.color3 }}>{item.cantidad}</Typography>
                                <IconButton
                                    onClick={() => {
                                        addItem(item);
                                    }}
                                    sx={{ color: settings?.color3 }}>
                                    <AddIcon />
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                ))}
                <Grid container sx={{
                    padding: '10px',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '10px' }}>
                        <Typography variant="h6" sx={{ color: settings?.color2 }}>Total: $ {carrito.reduce((acc, item) => acc + getPrecioTotal(item, item.detalles) * item.cantidad, 0)}</Typography>
                    </Box>
                </Grid>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '10px',
                    cursor: carrito.length === 0 ? 'not-allowed' : 'pointer',
                    backgroundColor: settings?.background_color3,
                    opacity: carrito.length === 0 ? 0.5 : 1,
                    borderRadius: '5px'
                }}
                    onClick={carrito.length > 0 ? planes.includes('5') ? handleFinalizarCompra : handleOpenModal : null}
                >
                    <Typography variant="h6" sx={{ color: settings?.color3 }}>Comprar</Typography>
                </Box>
            </Box>
            {
                !planes.includes('5') && (
                    <Modal
                        open={open}
                        onClose={() => setOpen(false)}
                        closeAfterTransition
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Slide direction="down" in={open} mountOnEnter unmountOnExit timeout={500}>
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    bgcolor: settings?.background_color1,
                                    boxShadow: 24,
                                    p: 4,
                                    overflowY: 'auto',
                                    paddingY: '40px',
                                    paddingX: isMobile ? '' : '20%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '25px',
                                    '&::-webkit-scrollbar': {
                                        width: '10px',
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        background: settings?.background_color2,
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        backgroundColor: settings?.background_color3,
                                        borderRadius: '20px',
                                    },
                                }}
                            >
                                <TextField
                                    label="Nombre"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    value={nombre}
                                    error={nombre === '' && error}
                                    onChange={(e) => setNombre(e.target.value)}
                                    sx={{
                                        '& label': {
                                            '&.Mui-focused': {
                                                color: settings?.color1
                                            },

                                            color: settings?.color1
                                        },
                                        '& input': {
                                            color: settings?.color1
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: settings?.color1
                                            },
                                            '&:hover fieldset': {
                                                borderColor: settings?.color1
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: settings?.color1
                                            }
                                        }
                                    }}
                                />
                                <TextField
                                    variant="outlined"
                                    value={descripcion}
                                    onChange={(e) => setDescripcion(e.target.value)}
                                    fullWidth
                                    rows={2}
                                    multiline
                                    placeholder="Ej: Sin tomate"
                                    label="Especificaciones"
                                    InputLabelProps={{ shrink: true }}
                                    sx={{
                                        '& label': {
                                            '&.Mui-focused': {
                                                color: settings?.color1
                                            },

                                            color: settings?.color1
                                        },
                                        '& textarea': {
                                            color: settings?.color1
                                        },

                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: settings?.color1
                                            },
                                            '&:hover fieldset': {
                                                borderColor: settings?.color1
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: settings?.color1
                                            }
                                        }
                                    }}
                                />
                                <TextField
                                    label="Teléfono"
                                    variant="outlined"
                                    fullWidth
                                    value={telefono}
                                    required
                                    error={telefono === null && error}
                                    onChange={(e) => {
                                        if (!/^\d*$/.test(e.target.value)) return;
                                        if (e.target.value.length > 10) return;

                                        setTelefono(e.target.value)
                                    }}
                                    sx={{
                                        marginBottom: '-20px',
                                        '& label': {
                                            '&.Mui-focused': {
                                                color: settings?.color1
                                            },
                                            color: settings?.color1
                                        },
                                        '& input': {
                                            color: settings?.color1
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: settings?.color1
                                            },
                                            '&:hover fieldset': {
                                                borderColor: settings?.color1
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: settings?.color1
                                            }
                                        }
                                    }}
                                />
                                <Box sx={{ width: '100%', mt: 2 }}>
                                    <Typography variant="h6" sx={{ color: settings?.color1, mb: 2, textAlign: 'center' }}>
                                        Elige cómo quieres recibir tu pedido:
                                    </Typography>
                                    <Grid container spacing={2} justifyContent="center" marginBottom={3} >
                                        <Grid item xs={6}>
                                            <Box
                                                onClick={() => setIsDelivery(false)}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    padding: 2,
                                                    borderRadius: 2,
                                                    cursor: 'pointer',
                                                    bgcolor: isDelivery === false ? settings?.background_color3 : 'transparent',
                                                    border: isDelivery === null && error && '1px solid red',
                                                }}
                                            >
                                                <IconButton
                                                    sx={{
                                                        color: isDelivery === false ? settings?.color3 : settings?.color2,
                                                        fontSize: 20,
                                                    }}
                                                >
                                                    <StorefrontIcon fontSize="inherit" />
                                                </IconButton>
                                                <Typography sx={{
                                                    color: isDelivery === false ? settings?.color3 : settings?.color2
                                                    , fontSize: '0.8em'
                                                }}>Retiro en local</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box
                                                onClick={() => setIsDelivery(true)}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    padding: 2,
                                                    borderRadius: 2,
                                                    cursor: 'pointer',
                                                    bgcolor: isDelivery === true ? settings?.background_color3 : 'transparent',
                                                    border: isDelivery === null && error && '1px solid red',
                                                }}
                                            >
                                                <IconButton
                                                    sx={{
                                                        color: isDelivery === true ? settings?.color3 : settings?.color2,
                                                        fontSize: 20,
                                                    }}
                                                >
                                                    <LocalShippingIcon fontSize="inherit" />
                                                </IconButton>
                                                <Typography sx={{
                                                    color: isDelivery === true ? settings?.color3 : settings?.color2,
                                                    fontSize: '0.8em'
                                                }}>Envío a domicilio</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Collapse in={isDelivery} timeout="auto" unmountOnExit>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Barrio"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    error={barrio === '' && error}
                                                    value={barrio}
                                                    onChange={(e) => setBarrio(e.target.value)}
                                                    sx={{
                                                        marginBottom: '20px',
                                                        '& label': {
                                                            '&.Mui-focused': {
                                                                color: settings?.color1
                                                            },
                                                            color: settings?.color1
                                                        },
                                                        '& input': {
                                                            color: settings?.color1
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: settings?.color1
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: settings?.color1
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: settings?.color1
                                                            }
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Dirección"
                                                    variant="outlined"
                                                    fullWidth
                                                    error={direccion === '' && error}
                                                    required
                                                    value={direccion}
                                                    onChange={(e) => setDireccion(e.target.value)}
                                                    sx={{
                                                        '& label': {
                                                            '&.Mui-focused': {
                                                                color: settings?.color1
                                                            },
                                                            color: settings?.color1
                                                        },
                                                        '& input': {
                                                            color: settings?.color1
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: settings?.color1
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: settings?.color1
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: settings?.color1
                                                            }
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Collapse>
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    padding: '10px',
                                    cursor: 'pointer',
                                    backgroundColor: settings?.background_color3,
                                    borderRadius: '5px'
                                }}
                                    onClick={handleConfirmOrder}
                                >
                                    <Typography variant="h6" sx={{ color: settings?.color3 }}>Confirmar Pedido</Typography>
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                }}
                                    onClick={() => {
                                        setOpen(false);
                                        setError(false);
                                    }}
                                >
                                    <Typography variant="h6" sx={{ color: settings?.color1 }}>Volver</Typography>
                                </Box>
                            </Box>
                        </Slide>
                    </Modal>
                )
            }
        </Box >
    );
}
