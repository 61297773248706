import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box, Paper } from "@mui/material";
import useFetch, { host } from '../../../Utils/Fetchs';
import BotonLoading from '../../../Components/ABM/MUIComponents/BotonLoading';
import DragDrop from '../../../Components/ABM/MUIComponents/DragDrop';
import swal from 'sweetalert';
import { heIL } from '@mui/x-data-grid';

export default function OrdenarCategorias() {
    const [categorias, setCategorias] = useState([]);
    const [loading, setLoading] = useState(false);
    const { getFetch, postFetch } = useFetch();

    const url = host + 'categorias/ordenar/';

    useEffect(() => {
        getFetch(url, true)
            .then(data => {
                setCategorias(data.map(cat => ({ id: cat.id, content: cat.nombre })));
            })
            .catch(error => {
                console.error('Error al obtener las categorías:', error);
                swal('Hubo un error al obtener las categorías');
            });
    }, []);

    const handleSaveOrder = () => {
        setLoading(true);
        const orderedIds = categorias.map(cat => parseInt(cat.id));
        postFetch(`${host}categorias/ordenar/`, { 'categorias': orderedIds }, true)
            .then(() => {
                swal('Orden guardado correctamente', '', 'success');
            })
            .catch(error => {
                console.error('Error al guardar el orden:', error);
                swal('Hubo un error al guardar el orden');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}>
            <Typography variant="h4" component="h1" gutterBottom>
                Ordenar Categorías
            </Typography>

            <Grid item xs={12} mt={2} style={{ width: '100%', overflowX: 'hidden' }}>
                <DragDrop items={categorias} setItems={setCategorias} />
            </Grid>
            <Grid item xs={12} mt={10} display="flex" justifyContent="center">
                <BotonLoading
                    loading={loading}
                    width='250px'
                    style={{ height: '50px' }}
                    funcion={handleSaveOrder}
                    color="primary"
                    variant="contained"
                >
                    Guardar Orden
                </BotonLoading>
            </Grid>
        </Box>
    );
}
