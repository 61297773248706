import React, { useState, useEffect } from 'react';
import { useProducto } from '../../../Utils/UProducto';
import DefaultModal from "../../../Components/ABM/MUIComponents/DefaultModal";
import { Grid, TextField, Typography } from "@mui/material";
import BotonLoading from "../../../Components/ABM/MUIComponents/BotonLoading";
import CustomizedSnackbars from '../../../Components/ABM/MUIComponents/CustomizedSnackbars';

export default function AMModalProduct(props) {
    const { isOpen, onClose, producto, setProductos, productos } = props;
    const [nombre, setNombre] = useState("");
    const [precio, setPrecio] = useState("");
    const [loading, setLoading] = useState(false);

    const { putProducto } = useProducto();

    useEffect(() => {
        if (producto) {
            setNombre(producto.name);
            setPrecio(producto.precio);
        }
    }, [producto]);

    async function handlePutProducto(id) {
        setLoading(true);
        let productoInProductos = productos.find(producto => producto.id === id);
        try {
            let formData = new FormData();
            formData.append('id', id);
            if (precio !== productoInProductos.precio) formData.append('precio', precio);

            await putProducto(formData).then(response => {
                setProductos(
                    productos.map(producto => {
                        if (producto.id === id) {
                            return response;
                        }
                        return producto;
                    })
                );
            });
            handleCloseModal();
        } catch (error) {
            alert(error);
        }
        finally {
            setLoading(false);
        }
    }

    const handlePrecioChange = (event) => {
        const inputPrice = event.target.value;
        if (/^\$?\d*\.?\d*$/.test(inputPrice)) {
            setPrecio(inputPrice);
        }
    };

    const handleCloseModal = () => {
        setPrecio("");
        setNombre("");
        onClose();
    };

    return (
        <DefaultModal
            open={isOpen}
            onClose={() => {
                handleCloseModal();
            }}
        >
            <Grid
                container
                item
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                xs={12}
            >
                <Grid
                    container
                    item
                    xs={8}
                    justifyContent="flex-end"
                    style={{ gap: "30px" }}
                >
                    <Typography
                        variant="h4"
                        component="div"
                        style={{ marginBottom: "2vh", width: '100%', textAlign: "center" }}
                    >
                        Editar Producto
                    </Typography>
                    <TextField
                        label="Nombre"
                        variant="outlined"
                        value={nombre}
                        fullWidth
                        disabled
                    />
                    <TextField
                        label="Precio"
                        variant="outlined"
                        value={precio}
                        fullWidth
                        onChange={handlePrecioChange}
                        placeholder="$"
                    />
                    <BotonLoading
                        funcion={() => handlePutProducto(producto.id)}
                        state={false}
                        loading={loading}
                    >
                        Editar
                    </BotonLoading>
                </Grid>
            </Grid>
        </DefaultModal>
    );
}
