import useFetch, { host } from "../Fetchs";

export function useSettings() {
    const { getFetch } = useFetch();

    async function getSettings(empresa) {
        return getFetch(`${host}settings/admin/temas/${empresa}/`, true);
    }

    async function getTemaActual(empresa) {
        return getFetch(`${host}settings/tema-actual/${empresa}/`, true);
    }

    return { getSettings, getTemaActual };
}
