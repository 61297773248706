import { Box, Button, Fade, Grid, Slide, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import whatsapp from '../../../medias/whatsapp.png';
import { ReactComponent as Logo } from '../../../assets/logo.svg';



function Home() {
    const isMobile = useMediaQuery('(max-width:900px)');

    useEffect(() => {
        const metaThemeColor = document.querySelector('meta[name=theme-color]');
        metaThemeColor.setAttribute('content', color1);
    }, [])

    let color1 = "#FFF9EC"
    let color2 = "#F45A22"

    return (
        <Grid container sx={{
            overflowY: "auto",
            height: "100vh",
        }}>
            <Grid container item
                sx={{
                    width: "100vw",
                    minHeight: "100vh",
                    background: color1,
                    flexDirection: "row",
                    flexWrap: "wrap",
                }}>
                <Grid container item
                    xs={12}
                    sm={12}
                    md={6}

                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        marginTop: "20vh",
                        gap: "50px",
                        paddingX: "30px",
                        textAlign: "center",
                    }}>
                    <Box sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                    }}>
                        <Logo />
                    </Box>
                    <Fade in={true} timeout={2000}>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "50px",
                        }}>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: "20px",
                            }}>
                                <Typography variant="h4" sx={{
                                    fontWeight: "bold",
                                    fontFamily: "Lato",
                                }}>
                                    Potencia tu negocio con nostros
                                </Typography>
                                <Typography variant="h5" sx={{
                                    fontFamily: "Lato",
                                }}>
                                    Somos una plataforma que se enfoca en transformar tiendas tradicionales a través de tecnologías innovadoras para mejorar la experiencia del cliente y la eficiencia operativa.
                                </Typography>
                            </Box>
                            <Button variant="contained" color="primary"
                                sx={{
                                    background: color2,
                                    color: color1,
                                    borderRadius: "24px",
                                    fontSize: "1.2rem",
                                    "&:hover": {
                                        background: "#FFA500",
                                        color: "#FFF9Ec",
                                    }
                                }}
                                onClick={() => { window.location.href = `https://wa.me/5493534192373?text=Hola,%20me%20gustaría%20hacer%20la%20prueba%20gratuita%20de%20Karta!` }}>
                                Probar 30 diás gratis
                            </Button>
                        </Box>
                    </Fade>
                </Grid>
                <Grid item xs={12}
                    sm={12}
                    md={6} sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: isMobile ? "100px" : "0",
                        paddingBottom: isMobile ? "140px" : "0",
                    }}>
                    <Fade in={true} timeout={2000}>
                        <iframe title='showcase' src='https://karta.com.ar/demo' style={{
                            border: "1px solid black",
                            borderRadius: "24px",
                            height: isMobile ? "80vh" : "90vh",
                            maxWidth: "90vw",
                            aspectRatio: "9/16",
                            boxShadow: "0 4px 10px rgba(0, 0, 0, 1)",
                            background: "black"
                        }} />
                    </Fade>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Home;
